import React, { FormEvent, useState } from "react";
import emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import { generateUniqueID } from "../scripts/tools/utils";
import { changeUserPassword } from "../scripts/controllers/userController";
init('user_DvzluUOonFH13wInZ1rTp');


export default function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [username, setUsername] = useState('');
  const [sentEmail, setSentEmail] = useState(false);
  const [code, setCode] = useState('');
  const [id] = useState(generateUniqueID());

  const handleSendEmail = async (e: FormEvent) => {
    e.preventDefault();
    if (!email) return;
    const emailParams = {
      email,
      code: id,
    };
    await emailjs.send('service_nc96wce', 'template_we3id21', emailParams);
    setSentEmail(true);
  };

  const handleConfirmReset = async (e: FormEvent) => {
    e.preventDefault();
    if (id !== code) return alert('Invalid code');
    if (password !== newPassword) return alert('Passwords do not match');
    await changeUserPassword(password, username, email);
    location.replace('/login');
  };


  return (
    <div className="login-page">
      <h1>Reset Password</h1>
      {sentEmail ?
        <form className="login-page__form" style={{ paddingTop: '7rem' }} onSubmit={(e: FormEvent) => handleConfirmReset(e)}>
          <label>
            Code
            <input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </label>
          <label>
            Username
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <label>
            New Password
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <label>
            Confirm Password
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </label>
          <button type="submit">Confirm</button>
        </form>
        :
        <form className="login-page__form" onSubmit={(e: FormEvent) => handleSendEmail(e)}>
          <label>
            Email
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <button type="submit">Send Code</button>
        </form>
      }
      <a href="/login">Back to Login</a>
    </div>
  );
}
