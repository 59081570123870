import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { fetchGearState } from "../../redux/reducers/gearSlice";
import { addGear, deleteGear, editGear } from "../../scripts/controllers/gearController";
import { getRandomInt } from "../../scripts/tools/utils";


interface GearInterface {
  equipment: Gear[]
  tools: Gear[]
  instruments: Gear[]
  rations: Gear[]
}

export default function AdminGear() {
  const { equipment, tools, instruments, rations }: GearInterface = useAppSelector(fetchGearState);
  const [categorySelected, setCategorySelected] = useState('');
  const [gearEdited, setGearEdited] = useState<Gear>();

  const saveGearChanges = async (e: FormEvent) => {
    e.preventDefault();
    if (gearEdited.index === 'new-gear') {
      const updatedGear = {...gearEdited, index: `${gearEdited.name.toLowerCase()}-${getRandomInt(0, 9999)}`};
      await addGear(updatedGear);
    } else {
      await editGear(gearEdited);
    }
    window.location.href = '/admin?s=gear';
  };

  const handleDeleteGear = async (id: number) => {
    if (confirm('Are you sure?')) {
      await deleteGear(id);
      window.location.href = '/admin?s=gear';
    }
  };


  return (
    <div className="admin-page__gear">
      {!gearEdited ?
        <>
          {categorySelected === '' ?
            <div className="admin-page__gear-categories">
              <button onClick={() => setCategorySelected('equipment')}>Equipment</button>
              <button onClick={() => setCategorySelected('tool')}>Tools</button>
              <button onClick={() => setCategorySelected('instrument')}>Instruments</button>
              <button onClick={() => setCategorySelected('ration')}>Rations</button>
            </div>
            :
            <>
              <div className="admin-page__gear--top-buttons">
                <button onClick={() => setCategorySelected('')}>Back</button>
                <button onClick={() => setGearEdited({ id: 0, index: 'new-gear', name: '', desc: '', lbs: 0, cost: { amount: 0, currency: 'gp' }, type: categorySelected, qty: 1, carried: false })}>Add</button>
              </div>
              {categorySelected === 'equipment' &&
                equipment.map((gear: Gear) => {
                  return (
                    <div className="admin-page__gear-box" key={gear.id}>
                      <div className="admin-page__gear-box--title">
                        <h3>{ gear.name }</h3>
                        <button onClick={() => setGearEdited(gear)}>Edit</button>
                        <button onClick={() => handleDeleteGear(gear.id)}>Delete</button>
                      </div>
                      <p>{ gear.lbs } lbs, { gear.cost.amount } { gear.cost.currency }</p>
                      <p>{ gear.desc }</p>
                    </div>
                  );
                })
              }
              {categorySelected === 'tool' &&
                tools.map((gear: Gear) => {
                  return (
                    <div className="admin-page__gear-box" key={gear.id}>
                      <div className="admin-page__gear-box--title">
                        <h3>{ gear.name }</h3>
                        <button onClick={() => setGearEdited(gear)}>Edit</button>
                        <button onClick={() => handleDeleteGear(gear.id)}>Delete</button>
                      </div>
                      <p>{ gear.lbs } lbs, { gear.cost.amount } { gear.cost.currency }</p>
                      <p>{ gear.desc }</p>
                    </div>
                  );
                })
              }
              {categorySelected === 'instrument' &&
                instruments.map((gear: Gear) => {
                  return (
                    <div className="admin-page__gear-box" key={gear.id}>
                      <div className="admin-page__gear-box--title">
                        <h3>{ gear.name }</h3>
                        <button onClick={() => setGearEdited(gear)}>Edit</button>
                        <button onClick={() => handleDeleteGear(gear.id)}>Delete</button>
                      </div>
                      <p>{ gear.lbs } lbs, { gear.cost.amount } { gear.cost.currency }</p>
                      <p>{ gear.desc }</p>
                    </div>
                  );
                })
              }
              {categorySelected === 'ration' &&
                rations.map((gear: Gear) => {
                  return (
                    <div className="admin-page__gear-box" key={gear.id}>
                      <div className="admin-page__gear-box--title">
                        <h3>{ gear.name }</h3>
                        <button onClick={() => setGearEdited(gear)}>Edit</button>
                        <button onClick={() => handleDeleteGear(gear.id)}>Delete</button>
                      </div>
                      <p>{ gear.lbs } lbs, { gear.cost.amount } { gear.cost.currency }</p>
                      <p>{ gear.desc }</p>
                    </div>
                  );
                })
              }
            </>
          }
        </>
        :
        <form className="admin-page__gear-form" onSubmit={(e) => saveGearChanges(e)}>
          <div className="admin-page__gear-form--top-buttons">
            <button type="button" onClick={() => setGearEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__gear-form--inputs">
            <label>
              Title
              <input
                value={gearEdited.name}
                onChange={(e) => setGearEdited({...gearEdited, name: e.target.value})}
                required
              />
            </label>
            <label>
              Description
              <textarea
                value={gearEdited.desc}
                onChange={(e) => setGearEdited({...gearEdited, desc: e.target.value})}
              />
            </label>
            <label>
              Lbs
              <input
                value={gearEdited.lbs}
                onChange={(e) => setGearEdited({...gearEdited, lbs: Number(e.target.value)})}
                type="number"
                required
              />
            </label>
            <h2 style={{ 'marginBottom':'0' }}>Cost</h2>
            <label>
              Amount
              <input
                value={gearEdited.cost.amount}
                onChange={(e) => setGearEdited({...gearEdited, cost: { ...gearEdited.cost, amount: Number(e.target.value)}})}
                type="number"
                required
              />
            </label>
            <label>
              Currency
              <select
                value={gearEdited.cost.currency}
                onChange={(e) => setGearEdited({...gearEdited, cost: { ...gearEdited.cost, currency: e.target.value}})}
                required
              >
                <option value="gp">GP</option>
                <option value="sp">SP</option>
                <option value="cp">CP</option>
              </select>
            </label>
          </div>
        </form>
      }
    </div>
  );
}
