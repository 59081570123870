import api from "../config/axios";


// GET Routes

export const getAllFeatures = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/feature', auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllFeaturesByClass = async (characterClass: string) => {
  try {
    if (!characterClass) return [];
    const auth = { withCredentials: true };
    const res = await api.get(`/api/feature/${characterClass}`, auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editFeature = async (featureEdited: FeatureDisplay) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/feature', featureEdited, auth);
    featureEdited.featureList.forEach(async (feature: Feature) => {
      await api.put('/api/feature/list', feature);
    });
  } catch (error) {
    console.log(error);
  }
};
