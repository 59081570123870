import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterBlessings, setCharacterExhaustion, setCharacterFeatures, setCharacterLevel, setCharacterSkills } from "../../../redux/reducers/characterSlice";
import { determineClassFocusAbilityBonuses, determineClassStartingRestDice, getStartingGearFromClass } from "../../../scripts/characterSheetLogic";
import { rollDice } from "../../../scripts/diceRolls";
import { fetchBlessingsState } from "../../../redux/reducers/blessingsSlice";
import { characterStateHasIndex } from "../../../scripts/characterSheetLogic";
import { getAllFeaturesByClass } from "../../../scripts/controllers/featuresController";
import Dropdown from "../../Dropdowns/Dropdown";
import { fetchGearState } from "../../../redux/reducers/gearSlice";
import { fetchWeaponsState } from "../../../redux/reducers/weaponsSlice";
import { fetchCultureTraitsData } from "../../../redux/reducers/cultureTraitsSlice";
import PickClassModal from "./PickClassModal";
import PickCultureModal from "./PickCultureModal";
import PickCultureTraitsModal from "./PickCultureTraitsModal";
import PickAbilityScoresModal from "./PickAbilityScoresModal";
import PickStartingGearModal from "./PickStartingGearModal";
import PickStartingSilver from "./PickStartingSilverModal";


interface Props {
  index: string
}

interface GearState {
  equipment: Gear[]
  armor: Gear[]
  tools: Gear[]
  instruments: Gear[]
  rations: Gear[]
}

export default function NewCharacterModal({ index }: Props) {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const gearState: GearState = useAppSelector(fetchGearState);
  const weaponsState: Weapon[] = useAppSelector(fetchWeaponsState);
  const blessingsState: Blessing[] = useAppSelector(fetchBlessingsState);
  const cultureTraitsState: CultureTrait[] = useAppSelector(fetchCultureTraitsData);
  const [pageNumber, setPageNumber] = useState(1);
  const [blessingInput, setBlessingInput] = useState('anoited');
  const [trait1, setTrait1] = useState<CultureTrait>(null);
  const [trait2, setTrait2] = useState<CultureTrait>(null);
  const [trait3, setTrait3] = useState<CultureTrait>(null);
  const [gearOrSilver, setGearOrSilver] = useState('');
  const [rolledSilver, setRolledSilver] = useState(false);
  const [featureDisplay, setFeatureDisplay] = useState<FeatureDisplay>();
  const [featureSelected, setFeatureSelected] = useState<Feature>();
  const [startingGear, setStartingGear] = useState<StartingGearOptions>();
  const [option1, setOption1] = useState('');
  const [option2, setOption2] = useState('');
  const [option3, setOption3] = useState('');
  const [optionBackpack, setOptionBackpack] = useState('Basic Kit');


  useEffect(() => {
    const gearData = getStartingGearFromClass(characterState.characterClass, gearState, weaponsState);
    setStartingGear(gearData);   
    if (gearData) {
      setOption1(gearData.row1[0].subOptions && gearData.row1[0].subOptions.length > 0 ? gearData.row1[0].subOptions[0].name : gearData.row1[0].name);
      setOption2(gearData.row2[0].subOptions && gearData.row2[0].subOptions.length > 0 ? gearData.row2[0].subOptions[0].name : gearData.row2[0].name);
    }
  }, [characterState.characterClass]);

  const closeModal = () => {
    setPageNumber(1);
    setGearOrSilver('');
    setRolledSilver(false);
    dispatch(setCharacterLevel(1));
    handleStartingExhaustion();
    handleSkillBonuses();
    document.getElementById(`modal-container-${index}`).classList.add('hidden');
  };

  const handleStartingExhaustion = () => {
    const HAS_HARDENED_BLESSING = characterStateHasIndex(characterState.blessings, 'hardened');
    const { amount, type } = determineClassStartingRestDice(characterState.characterClass);
    
    if (HAS_HARDENED_BLESSING) {
      dispatch(setCharacterExhaustion(12 + (amount * type)));
    } else {
      dispatch(setCharacterExhaustion(12 + rollDice(amount, type).total));
    }
  };

  const handleSkillBonuses = () => {
    const skillBonuses: Skill[] = determineClassFocusAbilityBonuses(characterState.characterClass);
    const updatedSkills = characterState.skills.map((skill: Skill) => {
      if (skill.name === skillBonuses[0].name) {
        return { name: skill.name, type: skill.type, bonus: skillBonuses[0].bonus };
      } else if (skill.name === skillBonuses[1].name) {
        return { name: skill.name, type: skill.type, bonus: skillBonuses[1].bonus };
      } else {
        return skill;
      }
    });
    dispatch(setCharacterSkills(updatedSkills));
  };

  const handleAddNewBlessing = async (index: string) => {
    const newBlessing = blessingsState.find((blessing: Blessing) => blessing.index === index);
    dispatch(setCharacterBlessings([...characterState.blessings, newBlessing]));
    const features: FeatureDisplay[] = await getAllFeaturesByClass(characterState.characterClass) as any;
    setFeatureDisplay(features.find((feature: FeatureDisplay) => feature.level === 1));
    const modal = document.querySelector('.new-character-modal').parentElement;
    modal.style.setProperty('max-width', '50rem');
    modal.style.setProperty('max-height', '40rem');
    setPageNumber(5);
  };

  const handleAddFeature = (e: FormEvent) => {
    e.preventDefault();
    if (!featureSelected) return;
    dispatch(
      setCharacterFeatures(
        [featureSelected]
      )
    );
    setFeatureSelected(null);
    const modal = document.querySelector('.new-character-modal').parentElement;
    modal.style.setProperty('max-width', '35rem');
    modal.style.setProperty('max-height', '34rem');
    setPageNumber(6);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const goBack = () => {
    setPageNumber(pageNumber - 1);
  };
  

  return (
    <div className="level-up-modal new-character-modal">
      {pageNumber === 1 &&
        <PickClassModal nextPage={nextPage} characterState={characterState} />
      }
      {pageNumber === 2 &&
        <PickCultureModal
          nextPage={nextPage}
          goBack={goBack}
          characterState={characterState}
          cultureTraitsState={cultureTraitsState}
          setFeatureDisplay={setFeatureDisplay}
          setTrait1={setTrait1}
          setTrait2={setTrait2}
          setTrait3={setTrait3}
        />
      }
      {pageNumber === 3 &&
        <PickCultureTraitsModal
          nextPage={nextPage}
          goBack={goBack}
          characterState={characterState}
          cultureTraitsState={cultureTraitsState}
          trait1={trait1}
          trait2={trait2}
          trait3={trait3}
          setTrait1={setTrait1}
          setTrait2={setTrait2}
          setTrait3={setTrait3}
        />
      }
      {pageNumber === 4 &&
        <div className="dropdown-modal">
          <form onSubmit={(e) => {
            e.preventDefault();
            handleAddNewBlessing(blessingInput);
          }}>
            <h2>Pick a Blessing</h2>
            <Dropdown
              classData="level-up-modal__dropdown"
              emptyValueText={blessingInput}
              inputChange={(value: string) => setBlessingInput(value)}
              value={blessingsState.find((blessing: Blessing) => blessing.index === blessingInput)}
              options={blessingsState.map((blessing: Blessing) => {
                return { index: blessing.index, name: blessing.name, hoverContent: { title: blessing.name, desc: blessing.desc }};
              })}
            />
            <div className="dropdown-modal--bottom">
              <button type="button" onClick={goBack}>
                <img
                  src="/images/buttons/back_button.png"
                  alt="back button"
                />
              </button>
              <button type="submit">
                <img
                  src="/images/buttons/next_button.png"
                  alt="next button"
                />
              </button>
            </div>
          </form>
        </div>
      }
      {pageNumber === 5 &&
        <div className="level-up-modal features-modal feature-select-modal">
          {featureDisplay &&
            <>
              <h1>{ featureDisplay.title }</h1>
              <p>{ featureDisplay.desc }</p>
              <div className="feature-select-modal--bottom">
                <form onSubmit={(e) => handleAddFeature(e)}>
                  <div className="features-modal__features-list">
                    {featureDisplay.featureList.map((feature: Feature, i) => {
                      return (
                        <div className="feature-select-modal--feature" key={i}>
                          <h2>{ i === 0 ? 'Trait of Battle' : 'Trait of Grace' }</h2>
                          <button
                            onClick={() => setFeatureSelected(feature)}
                            type="button"
                            className={`features-item${featureSelected && featureSelected.index === feature.index && ' features-item--selected'}`}
                          >
                            <h4 className="features-item__option-btn">{ feature.name }</h4>
                            <p>{ feature.desc }</p>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <button type="button" onClick={goBack}>
                    <img
                      src="/images/buttons/back_button.png"
                      alt="back button"
                    />
                  </button>
                  <button type="submit">
                    <img
                      src="/images/buttons/next_button.png"
                      alt="next button"
                    />
                  </button>
                </form>
              </div>
            </>
          }
        </div>
      }
      {pageNumber === 6 &&
        <PickAbilityScoresModal nextPage={nextPage} goBack={goBack} characterState={characterState} />
      }
      {pageNumber === 7 &&
        <>
          {gearOrSilver === '' &&
            <div className="default-modal">
              <h2>Start with gear or silver?</h2>
              <button onClick={() => setGearOrSilver('gear')}>Gear</button>
              <button onClick={() => setGearOrSilver('silver')}>Silver</button>
            </div>
          }
          {gearOrSilver === 'gear' &&
            <PickStartingGearModal
              closeModal={closeModal}
              gearState={gearState}
              weaponsState={weaponsState}
              startingGear={startingGear}
              option1={option1}
              option2={option2}
              option3={option3}
              optionBackpack={optionBackpack}
              setOption1={setOption1}
              setOption2={setOption2}
              setOption3={setOption3}
              setOptionBackpack={setOptionBackpack}
              setGearOrSilver={setGearOrSilver}
            />
          }
          {gearOrSilver === 'silver' &&
            <PickStartingSilver
              closeModal={closeModal}
              characterState={characterState}
              rolledSilver={rolledSilver}
              setRolledSilver={setRolledSilver}
              setGearOrSilver={setGearOrSilver}
            />
          }
        </>
      }
    </div>
  );
}
