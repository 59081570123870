import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


// Clamp number between two values
export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

// Generate a PDF file from an element
export const generatePDF = async (pages: HTMLElement[]) => {
  const pdf = new jsPDF();
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  for (let i = 0; i < pages.length; i++) {
    const canvas = await html2canvas(pages[i]);
    const imageData = canvas.toDataURL('image/png');
    const imgProperties = pdf.getImageProperties(imageData);
    const imgWidth = pageWidth;
    const imgHeight = (imgProperties.height * imgWidth) / imgProperties.width;
    let remainingHeight = imgHeight;
    let currentPosition = 0;

    while (remainingHeight > 0) {
      pdf.addImage(imageData, 'PNG', 0, -currentPosition, imgWidth, imgHeight);
      remainingHeight -= pageHeight;
      currentPosition += pageHeight;
      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }

    if (i !== pages.length - 1) {
      pdf.addPage();
    }
  }

  pdf.save('character-sheet.pdf');
};

export const generateUniqueID = () => {
  let text = '';
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 20; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const convertItemPriceToDecimal = (num: number): string => {
  return (num / 100).toFixed(2);
};
