import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterAge, setCharacterCulture, setCharacterEyes, setCharacterGender, setCharacterHair, setCharacterHeight, setCharacterSkin, setCharacterWeight } from "../../../redux/reducers/characterSlice";
import { capitalize } from "../../../scripts/tools/stringUtils";
import Dropdown from "../../Dropdowns/Dropdown";


export default function SheetCharacterDetailsBlock() {
  const dispatch = useAppDispatch();
  const characterState = useAppSelector(fetchCharacterState);


  return (
    <div className="character-sheet__character-details">
      <table>
        <tbody>
          <tr>
            <td>
              AGE: 
              <input
                type="number"
                value={characterState.age}
                onChange={(e) => dispatch(setCharacterAge(e.target.value))}
              />
            </td>
            <td>
              GENDER: 
              <Dropdown
                value={{ index: characterState.gender, name: characterState.gender }}
                inputChange={(gender) => dispatch(setCharacterGender(capitalize(gender)))}
                classData="character-sheet__character-details--dropdown"
                emptyValueText="-- SELECT --"
                options={[
                  { index: 'male', name: 'Male' },
                  { index: 'female', name: 'Female' }
                ]}
              />
            </td>
          </tr>
          <tr>
            <td>
              HEIGHT: 
              <input
                value={characterState.height}
                onChange={(e) => dispatch(setCharacterHeight(e.target.value))}
              />
            </td>
            <td>
              WEIGHT: 
              <input
                value={characterState.weight}
                onChange={(e) => dispatch(setCharacterWeight(e.target.value))}
              />
            </td>
          </tr>
          <tr>
            <td>
              EYES: 
              <input
                value={characterState.eyes}
                onChange={(e) => dispatch(setCharacterEyes(e.target.value))}
              />
            </td>
            <td>
              HAIR: 
              <input
                value={characterState.hair}
                onChange={(e) => dispatch(setCharacterHair(e.target.value))}
              />
            </td>
          </tr>
          <tr>
            <td>
              CULTURE: { capitalize(characterState.culture) }
            </td>
            <td>
              SKIN: 
              <input
                value={characterState.skin}
                onChange={(e) => dispatch(setCharacterSkin(e.target.value))}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
