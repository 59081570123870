import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { fetchLanguagesState } from "../../redux/reducers/languagesSlice";
import { addLanguage, deleteLanguage, editLanguage } from "../../scripts/controllers/languageController";
import { capitalize } from "../../scripts/tools/stringUtils";
import { getRandomInt } from "../../scripts/tools/utils";


export default function AdminLanguages() {
  const languagesState: Language[] = useAppSelector(fetchLanguagesState);
  const [languageEdited, setLanguageEdited] = useState<Language>();

  const saveLanguageChanges = async (e: FormEvent) => {
    e.preventDefault();
    if (languageEdited.index === 'new-language') {
      const updatedLanguage = {...languageEdited, index: `${languageEdited.name.toLowerCase()}-${getRandomInt(0, 9999)}`};
      await addLanguage(updatedLanguage);
    } else {
      await editLanguage(languageEdited);
    }
    window.location.href = '/admin?s=languages';
  };

  const handleDeleteLanguage = async (id: number) => {
    if (confirm('Are you sure?')) {
      await deleteLanguage(id);
      window.location.href = '/admin?s=languages';
    }
  };


  return (
    <div className="admin-page__languages">
      {!languageEdited ?
        <>
          <div className="admin-page__languages-form--top-buttons">
            <button onClick={() => setLanguageEdited({ id: 0, index: 'new-language', name: '', culture: '', territory: '' })}>Add</button>
          </div>
          {languagesState.map((language: Language) => {
            return (
              <React.Fragment key={language.id}>
                <div className="admin-page__languages-box">
                  <div className="admin-page__languages-box--title">
                    <div>
                      <h3>
                        {language.name}
                        <button onClick={() => setLanguageEdited(language)} style={{ 'margin':'0 0.5rem' }}>Edit</button>
                        <button onClick={() => handleDeleteLanguage(language.id)}>Delete</button>
                      </h3>
                      <p>Culture: { capitalize(language.culture) } | Territory: { language.territory }</p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </>
        :
        <form className="admin-page__languages-form" onSubmit={(e) => saveLanguageChanges(e)}>
          <div className="admin-page__languages-form--top-buttons">
            <button type="button" onClick={() => setLanguageEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__languages-form--inputs">
            <label>
              Name
              <input
                value={languageEdited.name}
                onChange={(e) => setLanguageEdited({...languageEdited, name: e.target.value})}
                required
              />
            </label>
            <label>
              Culture
              <input
                value={languageEdited.culture}
                onChange={(e) => setLanguageEdited({...languageEdited, culture: e.target.value})}
                required
              />
            </label>
            <label>
              Territory
              <input
                value={languageEdited.territory}
                onChange={(e) => setLanguageEdited({...languageEdited, territory: e.target.value})}
                required
              />
            </label>
          </div>
        </form>
      }
    </div>
  );
}
