import React, { FormEvent, useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { setCharacterData } from "../../../redux/reducers/characterSlice";
import { getRandomInt } from "../../../scripts/tools/utils";
import { determineClassPreferredStat } from "../../../scripts/characterSheetLogic";

interface Props {
  nextPage: () => void
  goBack: () => void
  characterState: Character
}

interface Score {
  value: number
  selectedAbility: string
}


export default function PickAbilityScoresModal({ nextPage, goBack, characterState }: Props) {
  const dispatch = useAppDispatch();
  const [rolledAbilityScores, setRolledAbilityScores] = useState<Score[]>([]);
  const [abilityScores, setAbilityScores] = useState([
    { ability: 'phy', value: 0 },
    { ability: 'edu', value: 0 },
    { ability: 'tech', value: 0 },
    { ability: 'soc', value: 0 },
    { ability: 'awar', value: 0 },
    { ability: 'cour', value: 0 },
  ]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const newCharacterData = {
      ...characterState,
      physical: { name: 'physical', value: abilityScores[0].value, prof: false },
      education: { name: 'education', value: abilityScores[1].value, prof: false },
      technical: { name: 'technical', value: abilityScores[2].value, prof: false },
      social: { name: 'social', value: abilityScores[3].value, prof: false },
      awareness: { name: 'awareness', value: abilityScores[4].value, prof: false },
      courage: { name: 'courage', value: abilityScores[5].value, prof: false },
    };
    dispatch(setCharacterData(newCharacterData));
    nextPage();
  };

  const autoAssignAbilityScores = () => {
    const sortedRolledAbilityScores = [...rolledAbilityScores].sort((a, b) => b.value - a.value);
    const focusAbility: Score = determineClassPreferredStat(characterState.characterClass);
    const focusIndex = abilityScores.findIndex(ability => ability.ability === focusAbility.selectedAbility);
    
    // Assign the highest value to the focusAbility
    handleAbilityChange(sortedRolledAbilityScores[0].value, focusAbility.selectedAbility);
    sortedRolledAbilityScores[0].selectedAbility = focusAbility.selectedAbility;
    
    // Assign the rest of the values to the remaining abilities
    for (let i = 0, j = 1; i < abilityScores.length; i++) {
      if (i !== focusIndex) {
        handleAbilityChange(sortedRolledAbilityScores[j].value, abilityScores[i].ability);
        sortedRolledAbilityScores[j].selectedAbility = abilityScores[i].ability;
        j++;
      }
    }
  
    // Update the state of rolledAbilityScores
    setRolledAbilityScores(sortedRolledAbilityScores);
  };

  const rollForStats = () => {
    const randomScores = [];
    for (let i = 0; i < abilityScores.length; i++) {
      randomScores.push({ value: getRandomInt(1, 5), selectedAbility: '' });
    }
    setRolledAbilityScores(randomScores);
  };

  const scoreHasBeenSelected = (score: any) => {
    return score.value === 0;
  };

  const clearScoreInput = (ability: string) => {
    handleAbilityChange(0, ability);
    setRolledAbilityScores(prevScores => {
      const updatedScores = prevScores.map(score => {
        if (score.selectedAbility === ability) {
          return { ...score, selectedAbility: '' };
        }
        return score;
      });
      return updatedScores;
    });
  };

  const handleAbilityChange = (value: number, ability: string) => {
    setAbilityScores((prevAbilityScores) => {
      const updatedAbilityScores = prevAbilityScores.map((score) => {
        if (score.ability === ability) {
          return { ...score, value: value };
        } else {
          return score;
        }
      });
      return updatedAbilityScores;
    });
    selectScoreInput(value, ability);
  };

  const selectScoreInput = (scoreValue: number, ability: string) => {
    const scores = rolledAbilityScores;
    let selectionMade = false;
    rolledAbilityScores.forEach((score: Score, i) => {
      if (!selectionMade && score.value === scoreValue && score.selectedAbility === '') {
        scores[i].selectedAbility = ability;
        setRolledAbilityScores(scores);
        selectionMade = true;
      }
    });
  };


  return (
    <div className="level-up-modal ability-scores-modal">
      <h2>ROLL ABILITY SCORES</h2>
      <div>
        {rolledAbilityScores.length > 0 ?
          <>
            <div className="flex">
              <h4 className="ability-scores-modal__ability-scores-list">
                Results: {rolledAbilityScores.map((score: Score, i) => {
                  const length = rolledAbilityScores.length - 1;
                  return (
                    score.selectedAbility !== '' ?
                      <span key={i} className="ability-scores-modal__ability-scores-list--selected">
                        { score.value }{ length !== i ? ', ' : '' }
                      </span>
                      :
                      `${score.value}${ length !== i ? ', ' : ''}`
                  );
                })}
              </h4>
              <button type="button" onClick={autoAssignAbilityScores}>Auto Assign</button>
            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              {abilityScores.map((score, i) => (
                <label key={i} className="ability-scores-modal__score-input">
                  {score.ability.toUpperCase()}
                  {scoreHasBeenSelected(score) ?
                    <select
                      onChange={(e) => handleAbilityChange(Number(e.target.value), score.ability)}
                      id={`ability-input-${score.ability}`}
                      required
                    >
                      <option value="">-- SCORE --</option>
                      {rolledAbilityScores.map((_score, i) => (
                        <option
                          key={i}
                          value={_score.value}
                          disabled={(_score.selectedAbility !== score.ability && _score.selectedAbility !== '')}
                        >
                          {_score.value}
                        </option>
                      ))}
                    </select>
                    :
                    <select><option>{ score.value }</option></select>
                  }
                  <button
                    className="ability-scores-modal__score-input--delete-btn"
                    onClick={() => clearScoreInput(score.ability)}
                    type="button"
                  >
                    Clear
                  </button>
                </label>
              ))}
              <div className="ability-scores-modal--bottom">
                <button type="button" onClick={goBack}>
                  <img
                    src="/images/buttons/back_button.png"
                    alt="back button"
                  />
                </button>
                <button type="submit">
                  <img
                    src="/images/buttons/next_button.png"
                    alt="next button"
                  />
                </button>
              </div>
            </form>
          </>
          :
          <button onClick={rollForStats}>Roll for stats</button>
        }
      </div>
    </div>
  );
}
