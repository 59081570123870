import React, { FormEvent, useState } from "react";
import { loginUser } from "../scripts/controllers/userController";


export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLoginUser = (e: FormEvent) => {
    e.preventDefault();
    const user = { username: username, password: password };
    loginUser(user);
  };


  return (
    <div className="login-page">
      <h1>Login</h1>
      <form className="login-page__form" onSubmit={(e: FormEvent) => handleLoginUser(e)}>
        <label>
          Username
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
        </label>
        <label>
          Password
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </label>
        <button type="submit">Login</button>
      </form>
      <p className="login-page__link">Don&apos;t have an account? <a href="/register">Register</a></p>
      <a href="/reset-password">Forgot your password?</a>
    </div>
  );
}
