import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState } from "../../../redux/reducers/characterSlice";


export default function SheetHealthBlock() {
  const characterState = useAppSelector(fetchCharacterState);

  
  return (
    <div className="character-sheet__health">
      <div className="character-sheet__field character-sheet__health--exhaustion-title">
        <p>EXHAUSTION</p>
      </div>
      <div className="character-sheet__field character-sheet__health--exhaustion-value">
        <p>{ characterState.exhaustion }</p>
      </div>

      <div className="character-sheet__field character-sheet__health--temp-title">
        <p>TEMPORARY</p>
      </div>
      <div className="character-sheet__field character-sheet__health--temp-value"></div>
    </div>
  );
}
