import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchCharacterState, setCharacterCp, setCharacterGear, setCharacterGp, setCharacterSp } from "../../../redux/reducers/characterSlice";
import { fetchGearState } from "../../../redux/reducers/gearSlice";
import Dropdown from "../../Dropdowns/Dropdown";
import { getMaxCarryWeight } from "../../../scripts/characterSheetLogic";


interface GearInterface {
  equipment: Gear[]
  armor: Gear[]
  tools: Gear[]
  instruments: Gear[]
  rations: Gear[]
}

export default function SheetGearBlock() {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const { equipment, armor, tools, instruments, rations }: GearInterface = useAppSelector(fetchGearState);
  const [blankRows, setBlankRows] = useState([]);
  const descBox: any = document.getElementById('desc-box');
  const descBoxText: any = document.querySelector('#desc-box p');

  useEffect(() => {
    getBlankRows();
  }, [characterState]);


  const showDescOnHover = (e: any, desc: string) => {
    if (!desc) return;
    descBox.classList.remove('hidden');
    descBox.style.top = `${e.pageY}px`;
    descBox.style.left = `${e.pageX + 60}px`;
    descBoxText.innerText = desc;
  };

  const showDescStopHover = (e: any) => {
    descBox.classList.add('hidden');
    descBoxText.innerText = '';
  };

  // Get the amount of empty rows to render
  const getBlankRows = () => {
    const numOfBlankRows = 55 - characterState.gear.length;
    const emptyRows = [];
    for (let i = 0; i < numOfBlankRows; i++) {
      emptyRows.push([...blankRows, i]);
    }
    setBlankRows(emptyRows);
  };

  // Get item data from reducer
  const findGear = (index: string): Gear => {
    const equipmentSearch = equipment.find((gear: Gear) => gear.index === index);
    const armorSearch = armor.find((gear: Gear) => gear.index === index);
    const toolsSearch = tools.find((gear: Gear) => gear.index === index);
    const instrumentsSearch = instruments.find((gear: Gear) => gear.index === index);
    const rationsSearch = rations.find((gear: Gear) => gear.index === index);
    
    if (equipmentSearch) {
      return equipment.find((gear: Gear) => gear.index === index);
    } else if (armorSearch) {
      return armor.find((armor: Gear) => armor.index === index);
    } else if (toolsSearch) {
      return tools.find((gear: Gear) => gear.index === index);
    } else if (instrumentsSearch) {
      return instruments.find((gear: Gear) => gear.index === index);
    } else if (rationsSearch) {
      return rations.find((gear: Gear) => gear.index === index);
    }
  };

  // Detect if the character already has the same gear as passed in
  const findCharacterAlreadyHasGear = (index: string): boolean => {
    return characterState.gear.some((gear: Gear) => gear.index === index);    
  };

  // Update gear reducer with new gear data
  // If the gear already exists then add 1 to the qty
  const handleAddNewGear = (index: string) => {
    // Check if GO BACK was selected
    if (index === 'back') {
      return;
    }

    const newGear: Gear = findGear(index);
    const characterAlreadyHasGear = findCharacterAlreadyHasGear(index);

    if (characterAlreadyHasGear) {
      const updatedCharacterState = characterState.gear.map((gear: Gear) => {
        if (gear.index === index) {
          return {
            id: gear.id,
            index: gear.index,
            name: gear.name,
            desc: gear.desc,
            lbs: gear.lbs,
            cost: gear.cost,
            type: gear.type,
            qty: gear.qty + 1,
            carried: gear.carried,
          };
        }
        return gear;
      });
      dispatch(setCharacterGear(updatedCharacterState));
    } else {
      dispatch(setCharacterGear([...characterState.gear, newGear]));
    }
  };

  // Delete a gear row
  // TODO: If the qty is greater than 1 it should give the option to remove a certain amount
  const removeBlankRow = (index: string) => {
    const updatedCharacterState = characterState.gear.map((gear: Gear) => {
      if (gear.index === index) {
        return {
          id: gear.id,
          index: gear.index,
          name: gear.name,
          desc: gear.desc,
          lbs: gear.lbs,
          cost: gear.cost,
          type: gear.type,
          qty: gear.qty - 1,
          defense: gear.defense,
          carried: gear.carried
        };
      } else {
        return gear;
      }
    });
    dispatch(setCharacterGear(updatedCharacterState.filter((gear: Gear) => {
      return gear.qty > 0;
    })));
  };

  // Returns the total amount of lbs in gear
  const getTotalLbs = (): number => {
    let totalLbs = 0;
    characterState.gear.forEach((gear: Gear) => {
      totalLbs += gear.lbs * gear.qty;
    });
    characterState.weapons.forEach((weapon: Weapon) => {
      totalLbs += weapon.lbs * weapon.qty;
    });
    return totalLbs;
  };


  return (
    <div className="character-sheet__gear">
      <div className="character-sheet__gear--top-bar">
        <p>Place a star by equipped or carried gear</p>
        <div className="character-sheet__gear--top-bar-currency">
          <label>
            <span style={{ 'color':'var(--dark-gold-2)' }}>GOLD</span>
            <input
              value={characterState.gp}
              onChange={(e) => dispatch(setCharacterGp(Number(e.target.value)))}
              type="number"
            />
          </label>
          <label>
            <span style={{ 'color':'var(--dark-gray-0)' }}>SILVER</span>
            <input
              value={characterState.sp}
              onChange={(e) => dispatch(setCharacterSp(Number(e.target.value)))}
              type="number"
            />
          </label>
          <label>
            <span style={{ 'color':'#6e3700' }}>COPPER</span>
            <input
              value={characterState.cp}
              onChange={(e) => dispatch(setCharacterCp(Number(e.target.value)))}
              type="number"
            />
          </label>
        </div>
      </div>

      {/* Gear header */}
      <div className="character-sheet__gear-container--headers">
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--name-title">
          <p>GEAR</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--qty-title">
          <p>Qty</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--carried-title"></div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--lbs-title">
          <p>Lbs</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--name-title">
          <p>GEAR</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--qty-title">
          <p>Qty</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--carried-title"></div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--lbs-title">
          <p>Lbs</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--name-title">
          <p>GEAR</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--qty-title">
          <p>Qty</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--carried-title"></div>
        <div className="character-sheet__field character-sheet__gear-container-header character-sheet__gear-container-header--lbs-title">
          <p>Lbs</p>
        </div>
      </div>

      <div className="character-sheet__gear-container">
        {/* Gear content */}
        {characterState.gear.filter((gear: Gear) => gear.type !== 'armor').map((gear: Gear, i) => {
          return (
            <div className="character-sheet__gear-container--row" key={i}>
              {/* "i + 2" is to put it under the titles */}
              <div
                onMouseEnter={(e) => showDescOnHover(e, gear.desc)}
                onMouseLeave={(e) => showDescStopHover(e)}
                className={`character-sheet__field character-sheet__gear-container--name`}
              >
                <p>{ gear.name }</p>
              </div>
              <div className={`character-sheet__field character-sheet__gear-container--qty character-sheet__gear-container--qty-container`}>
                { gear.qty }
              </div>
              <div className={`character-sheet__field character-sheet__gear-container--carried character-sheet__gear-container--carried-container`}>
                { gear.carried ? <span className="gear-circle gear-circle--filled"></span> : <span className="gear-circle"></span> }
              </div>
              <div className={`character-sheet__field character-sheet__gear-container--lbs`}>
                <p>{ gear.lbs * gear.qty }</p>
                {/* Delete row button */}
                <button
                  className="character-sheet__gear-container--delete-btn"
                  onClick={() => removeBlankRow(gear.index)}
                >
                  <img src="/images/icons/trash.svg" alt="Trash can" draggable={false} />
                </button>
              </div>
            </div>
          );
        })}


        {/* Blank rows */}
        {blankRows.map((row, i) => {
          return (
            <div className="character-sheet__gear-container--row" key={i}>
              {/* Checks if this is the first blank row */}
              {/* Makes the first blank row an input to add a new gear */}
              {row === blankRows[0] ?
                <>
                  <div className="character-sheet__field character-sheet__gear-container--name">
                    <Dropdown
                      classData="pdf-remove"
                      emptyValueText="-- ADD GEAR --"
                      inputChange={(value: string) => handleAddNewGear(value)}
                      value={{ index: 'test', name: "-- ADD GEAR --" }}
                      options={[
                        { index: 'equipment', name: 'EQUIPMENT', subOptions: 
                          equipment.map((gear: Gear) => {
                            return { index: gear.index, name: gear.name };
                          })},
                        { index: 'tool', name: 'TOOLS', subOptions:
                          tools.map((gear: Gear) => {
                            return { index: gear.index, name: gear.name };
                          })},
                        { index: 'instrument', name: 'INSTRUMENTS', subOptions:
                          instruments.map((gear: Gear) => {
                            return { index: gear.index, name: gear.name };
                          })},
                        { index: 'ration', name: 'RATIONS', subOptions:
                          rations.map((gear: Gear) => {
                            return { index: gear.index, name: gear.name };
                          })}
                      ]}
                    />
                  </div>
                  <div className={`character-sheet__field character-sheet__gear-container--qty`}></div>
                  <div className={`character-sheet__field character-sheet__gear-container--carried`}></div>
                  <div className={`character-sheet__field character-sheet__gear-container--lbs`}></div>
                </>
                :
                <>
                  <div className={`character-sheet__field character-sheet__gear-container--name character-sheet__gear-container--name-empty`}></div>
                  <div className={`character-sheet__field character-sheet__gear-container--qty`}></div>
                  <div className={`character-sheet__field character-sheet__gear-container--carried`}></div>
                  <div className={`character-sheet__field character-sheet__gear-container--lbs`}></div>
                </>
              }
            </div>
          );
        })}
        <div className="character-sheet__field character-sheet__gear-container--max-carry-weight">
          <p className="character-sheet__gear-container--max-carry-weight-title">Max carry weight = 100 lbs + 25 lbs, for each point in PHY stat. PUSH/PULL = x4.</p>
        </div>
        <div className="character-sheet__field character-sheet__gear-container--total-lbs">
          <p className="character-sheet__gear-container--total-lbs-title">Total Weight Carried</p>
          <span className="character-sheet__gear-container--total-lbs-border"></span>

          <p className={`character-sheet__gear-container--total-lbs-amount ${getTotalLbs() > getMaxCarryWeight(characterState.physical.value) ? 'character-sheet__gear-container--total-lbs-amount--exceeded' : ''}`}>
            { getTotalLbs() } / { getMaxCarryWeight(characterState.physical.value) } lbs
          </p>
        </div>
      </div>
    </div>
  );
}
