import api from '../config/axios';
import { createCart } from './shopController';

interface NewUser {
    username: string
    password: string
}

// === GET routes === //

export const getUser = async () => {
  try {
    const config = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    };
    const res = await api.get('/api/user', config);
    return res.data.user;
  } catch(err) {
    console.log(err);
  }
};

// === POST routes === //

export const registerUser = async (payload: NewUser) => {
  try {
    const config = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    };
    await api.post('/api/user/register', payload, config);
    await api.post('/api/user/login', payload, config);
    await createCart();
    window.location.replace('/');
  } catch(err) {
    console.log(err);
  }
};

export const loginUser = async (payload: NewUser) => {
  try {
    const config = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    };
    await api.post('/api/user/login', payload, config);
    window.location.replace('/');
  } catch(err) {
    console.log(err);
  }
};

export const logout = async () => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/user/logout', auth);
    window.location.replace('/');
  } catch(err) {
    console.log(err);
  }
};

// === PUT routes === //

export const setUserProfilePic = async (url: string) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/user', { profilePic: url }, auth);
  } catch(err) {
    console.log(err);
  }
};

export const changeUserPassword = async (password: string, username: string, email: string) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/user/reset-password', { password, username, email }, auth);
  } catch(err) {
    console.log(err);
  }
};
