import React, { FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchSmallFeaturedContentState, setSmallFeaturedContentsData } from "../../redux/reducers/featuredContentSlice";
import { editSmallFeaturedContent } from "../../scripts/controllers/featuredContentController";
import { deleteImageFirebase, uploadImageFirebase } from "../../scripts/tools/firebaseUtils";


export default function AdminSmallFeaturedBoxes() {
  const dispatch = useAppDispatch();
  const featuredContentState: SmallFeaturedContent = useAppSelector(fetchSmallFeaturedContentState);
  const [featureBoxImg1, setFeatureBoxImg1] = useState<any>();
  const [featureBoxImg2, setFeatureBoxImg2] = useState<any>();
  const [featureBoxImg3, setFeatureBoxImg3] = useState<any>();
  const [featureBoxImg4, setFeatureBoxImg4] = useState<any>();
  const [featureBoxImgLoading, setFeatureBoxImgLoading] = useState(false);
  
  const handleEditFeaturedContent = async (e: FormEvent) => {
    e.preventDefault();
    let updatedFeaturedContent = featuredContentState;
    setFeatureBoxImgLoading(true);

    // Image 1
    if (featureBoxImg1) {
      await uploadImageFirebase(featureBoxImg1, (url: string) => {
        updatedFeaturedContent = { ...updatedFeaturedContent, featureBoxImg1: url };
      });
    } else {
      console.error('No image selected');
    }
    // Image 2
    if (featureBoxImg2) {
      await uploadImageFirebase(featureBoxImg2, (url: string) => {
        updatedFeaturedContent = { ...updatedFeaturedContent, featureBoxImg2: url };
      });
    } else {
      console.error('No image selected');
    }
    // Image 3
    if (featureBoxImg3) {
      await uploadImageFirebase(featureBoxImg3, (url: string) => {
        updatedFeaturedContent = { ...updatedFeaturedContent, featureBoxImg3: url };
      });
    } else {
      console.error('No image selected');
    }
    // Image 4
    if (featureBoxImg4) {
      await uploadImageFirebase(featureBoxImg4, (url: string) => {
        updatedFeaturedContent = { ...updatedFeaturedContent, featureBoxImg4: url };
      });
    } else {
      console.error('No image selected');
    }

    dispatch(
      setSmallFeaturedContentsData(updatedFeaturedContent)
    );
    await editSmallFeaturedContent(updatedFeaturedContent);
    window.location.href = '/admin?s=small featured boxes';
  };

  const removeFeaturedImage = async (index: number) => {
    if (index === 1) {
      await deleteImageFirebase(featuredContentState.featureBoxImg1);
      await editSmallFeaturedContent({ ...featuredContentState, featureBoxImg1: null });
      dispatch(
        setSmallFeaturedContentsData({ ...featuredContentState, featureBoxImg1: null })
      );
    } else if (index === 2) {
      await deleteImageFirebase(featuredContentState.featureBoxImg2);
      await editSmallFeaturedContent({ ...featuredContentState, featureBoxImg2: null });
      dispatch(
        setSmallFeaturedContentsData({ ...featuredContentState, featureBoxImg2: null })
      );
    } else if (index === 3) {
      await deleteImageFirebase(featuredContentState.featureBoxImg3);
      await editSmallFeaturedContent({ ...featuredContentState, featureBoxImg3: null });
      dispatch(
        setSmallFeaturedContentsData({ ...featuredContentState, featureBoxImg3: null })
      );
    } else {
      await deleteImageFirebase(featuredContentState.featureBoxImg4);
      await editSmallFeaturedContent({ ...featuredContentState, featureBoxImg4: null });
      dispatch(
        setSmallFeaturedContentsData({ ...featuredContentState, featureBoxImg4: null })
      );
    }
  };
  

  return (
    <div className="admin-page__featured-box">
      <form onSubmit={(e) => handleEditFeaturedContent(e)} className="admin-page__featured-box-form">
        <div className="admin-page__featured-box-form--top-buttons">
          <button type="submit">Save</button>
        </div>
        {featureBoxImgLoading ?
          <p>Loading...</p>
          :
          <>
            <label>
              Small Box Image 1
              <input
                type="file"
                onChange={(e) => setFeatureBoxImg1(e.target.files[0])}
                accept="image/*"
              />
              {/* <button type="button" onClick={() => removeFeaturedImage(1)}>
                Remove Image
              </button> */}
            </label>
            <label>
              Small Box Image 2
              <input
                type="file"
                onChange={(e) => setFeatureBoxImg2(e.target.files[0])}
                accept="image/*"
              />
              {/* <button type="button" onClick={() => removeFeaturedImage(2)}>
                Remove Image
              </button> */}
            </label>
            <label>
              Small Box Image 3
              <input
                type="file"
                onChange={(e) => setFeatureBoxImg3(e.target.files[0])}
                accept="image/*"
              />
              {/* <button type="button" onClick={() => removeFeaturedImage(3)}>
                Remove Image
              </button> */}
            </label>
            <label>
              Small Box Image 4
              <input
                type="file"
                onChange={(e) => setFeatureBoxImg4(e.target.files[0])}
                accept="image/*"
              />
              {/* <button type="button" onClick={() => removeFeaturedImage(4)}>
                Remove Image
              </button> */}
            </label>
          </>
        }
      </form>
    </div>
  );
}
