import React, { useEffect, useState } from "react";
import { checkout, editCart, getAllProducts, getPurchaseHistory } from "../scripts/controllers/shopController";
import { Link } from "react-router-dom";
import { clamp, convertItemPriceToDecimal } from "../scripts/tools/utils";
import Layout from "../components/Layout";
import { useAtom } from "jotai";
import { cartAtom } from "../scripts/atoms/state";


export default function CartPage() {
  const [cart, setCartData] = useAtom<Product[]>(cartAtom);
  const [productList, setProductList] = useState<Product[]>([]);
  const [purchaseHistory, setPurchaseHistory] = useState<PurchasedProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = (await getAllProducts()).map((product: Product) => {
        const existingItem = cart.find((item) => item.id === product.id);
        if (existingItem) {
          return { ...product, ...existingItem };
        } else {
          return null;
        }
      }).filter((product: Product) => product);
      setProductList(response);
    };
    fetchProducts();
  }, [cart]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPurchaseHistory();
      setPurchaseHistory(res);
    };
    fetchData();
  }, []);

  const handleCheckout = async () => {
    const updatedCart = cart.map((item: Product) => {
      return { price: item.price.id, quantity: item.quantity > 0 ? item.quantity : 1 };
    });
    if (cart.length > 0) await checkout(updatedCart);
  };

  const removeItemFromCart = async (selectedProduct: Product) => {
    const updatedCart = productList.filter((product: Product) => product !== selectedProduct);
    await editCart(updatedCart);
    setCartData(updatedCart);
  };

  const getTotalCost = () => {
    let cost = 0;
    productList.forEach((product: Product) => {
      cost += (product.price.unit_amount * product.quantity);
    });
    return convertItemPriceToDecimal(cost);
  };

  const changeQty = async (item: Product, value: string) => {
    const prod = productList.find((product: Product) => product.id === item.id);
    const prev = purchaseHistory.find((prod: PurchasedProduct) => prod.product === item.id);
    const maxQty = item.metadata.maxQty - (prev ? prev.qty : 0);
    const updatedCart = [...cart.filter((product: Product) => product.id !== item.id), { ...prod, quantity: clamp(Number(value), 0, maxQty) }];
    await editCart(updatedCart);
    setCartData(updatedCart);
  };
  
  
  return (
    <Layout>
      <div className="cart-page-container">
        <div className="cart-page">
          <h1>Your Cart</h1>
          {productList.map((product: Product) => {
            return (
              <div key={product.id} className="cart-page__product">
                <img src={product.images[0]} alt="product image" />
                <div className="cart-page__product--box">
                  <div style={{ 'display':'flex', 'gap':'0.6rem' }}>
                    <h2>{ product.name }</h2>
                    <button
                      className="cart-page__product--remove-btn"
                      onClick={() => removeItemFromCart(product)}
                    >
                      Remove
                    </button>
                  </div>
                  <div className="cart-page__price-container">
                    <p>${ convertItemPriceToDecimal(product.price.unit_amount * product.quantity) }</p>
                    {product.metadata.maxQty > 1 &&
                      <label className="cart-page__product--qty">
                        Qty
                        <input
                          type="number"
                          value={product.quantity === 0 ? '' : product.quantity}
                          onChange={(e) => changeQty(product, e.target.value)}
                        />
                      </label>
                    }
                  </div>
                </div>
              </div>
            );
          })}
          <p className="cart-page__total-cost">Total: ${ getTotalCost() }</p>

          <button
            className="cart-page__checkout-btn"
            onClick={handleCheckout}
          >
            Checkout
          </button>
          <Link to="/resources" className="cart-page__back-btn">Back</Link>
        </div>
      </div>
    </Layout>
  );
}
