import React from "react";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { cartAtom } from "../../scripts/atoms/state";


export default function ResourcesPageTopBar() {
  const [cart] = useAtom<Product[]>(cartAtom);
  
  const getCartLength = (): number => {
    let count = 0;
    cart.forEach((item: Product) => {
      count += item.quantity;
    });
    return count;
  };


  return (
    <div className="resources-page__top-bar">
      <div className="resources-page__top-bar--container">
        <h2 className="resources-page__top-bar--title">Merchant Shop</h2>
        <Link to="/resources/cart" className="resources-page__top-bar--cart">
          <img src="/images/icons/cart-icon.svg" alt="shopping cart" />Cart ({ getCartLength() })
        </Link>
      </div>
    </div>
  );
}
