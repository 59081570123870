import React, { ReactNode, useState } from "react";


interface Props {
  children: ReactNode
}

export default function Carousel({ children }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const count = React.Children.count(children);
  
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : count - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex < count - 1 ? prevIndex + 1 : 0));
  };


  return (
    <div className="carousel">
      <button className="carousel__left-btn" onClick={handlePrevClick}>&lt;</button>
      <div className="carousel__content">
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            className={`carousel__slide ${index === currentIndex ? 'selected' : 'hidden'}`}
          >
            {child}
          </div>
        ))}
      </div>
      <button className="carousel__right-btn" onClick={handleNextClick}>&gt;</button>
    </div>
  );
}
