import React from "react";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchCharacterState, setCharacterAwareness, setCharacterCourage, setCharacterEducation, setCharacterPhysical, setCharacterSocial, setCharacterTechnical } from "../../../redux/reducers/characterSlice";


export default function SheetAbilityScoreBlock() {
  const dispatch = useAppDispatch();
  const characterState = useAppSelector(fetchCharacterState);


  return (
    <div className="character-sheet__ability-scores">
      <div className="character-sheet__field character-sheet__ability-scores--title">
        <p>Ability Scores</p>
      </div>

      {/* Physical Ability */}
      <div className="character-sheet__field character-sheet__ability-scores--physical">
        <p>PHYSICAL</p>
      </div>
      <input
        className="character-sheet__field character-sheet__ability-scores--physical-value"
        value={characterState.physical.value}
        onChange={(e) => dispatch(setCharacterPhysical({ value: Number(e.target.value), prof: characterState.physical.prof }))}
        type="number"
      />

      {/* Education Ability */}
      <div className="character-sheet__field character-sheet__ability-scores--education">
        <p>EDUCATION</p>
      </div>
      <input
        className="character-sheet__field character-sheet__ability-scores--education-value"
        value={characterState.education.value}
        onChange={(e) => dispatch(setCharacterEducation({ value: Number(e.target.value), prof: characterState.education.prof }))}
        type="number"
      />

      {/* Technical Ability */}
      <div className="character-sheet__field character-sheet__ability-scores--technical">
        <p>TECHNICAL</p>
      </div>
      <input
        className="character-sheet__field character-sheet__ability-scores--technical-value"
        value={characterState.technical.value}
        onChange={(e) => dispatch(setCharacterTechnical({ value: Number(e.target.value), prof: characterState.technical.prof }))}
        type="number"
      />

      {/* Social Ability */}
      <div className="character-sheet__field character-sheet__ability-scores--social">
        <p>SOCIAL</p>
      </div>
      <input
        className="character-sheet__field character-sheet__ability-scores--social-value"
        value={characterState.social.value}
        onChange={(e) => dispatch(setCharacterSocial({ value: Number(e.target.value), prof: characterState.social.prof }))}
        type="number"
      />

      {/* Awareness Ability */}
      <div className="character-sheet__field character-sheet__ability-scores--awareness">
        <p>AWARENESS</p>
      </div>
      <input
        className="character-sheet__field character-sheet__ability-scores--awareness-value"
        value={characterState.awareness.value}
        onChange={(e) => dispatch(setCharacterAwareness({ value: Number(e.target.value), prof: characterState.awareness.prof }))}
        type="number"
      />

      {/* Courage Ability */}
      <div className="character-sheet__field character-sheet__ability-scores--courage">
        <p>COURAGE</p>
      </div>
      <input
        className="character-sheet__field character-sheet__ability-scores--courage-value"
        value={characterState.courage.value}
        onChange={(e) => dispatch(setCharacterCourage({ value: Number(e.target.value), prof: characterState.courage.prof }))}
        type="number"
      />
    </div>
  );
}
