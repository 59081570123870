import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA51AHUuIoxH5SKQxVKoElTaBV2aWp8p9g",
  authDomain: "antediluvian-db.firebaseapp.com",
  projectId: "antediluvian-db",
  storageBucket: "antediluvian-db.appspot.com",
  messagingSenderId: "255508400530",
  appId: "1:255508400530:web:3091dd95c3170d7bc2d94b",
  measurementId: "G-T03J5N7T6V"
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
