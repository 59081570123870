import React from "react";
import { Link } from "react-router-dom";

interface Props {
  setProductList: any
  defaultProductList: Product[]
}


export default function ResourcesPageLeftSidebar({ setProductList, defaultProductList }: Props) {
  const sortShopProducts = (filter: string) => {
    if (filter === 'all') return setProductList(defaultProductList);
    const filteredList = defaultProductList.filter((product: Product) => {
      if (product.metadata.type === filter) return product;
    });
    setProductList(filteredList);
  };


  return (
    <div className="resources-page__left-sidebar">
      <Link to="/purchases" className="resources-page__left-sidebar--purchases">
        My Purchases
      </Link>
      <h4>FEATURED CONTENT</h4>
      <button onClick={() => sortShopProducts('all')}>All</button>
      <button onClick={() => sortShopProducts('sourcebook')}>Sourcebooks</button>
      <button onClick={() => sortShopProducts('adventure')}>Adventures & Modules</button>
      <button onClick={() => sortShopProducts('online-content')}>Online Content</button>
      <button onClick={() => sortShopProducts('merch')}>Merch</button>
    </div>
  );
}
