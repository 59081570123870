export const defaultProfilePics: string[] = [
  // Blacksmith
  '/images/profilePics/blacksmith/female_blacksmith_20.png',
  '/images/profilePics/blacksmith/female_blacksmith_21.png',
  '/images/profilePics/blacksmith/female_blacksmith_22.png',
  '/images/profilePics/blacksmith/female_blacksmith_23.png',
  '/images/profilePics/blacksmith/female_blacksmith_24.png',
  '/images/profilePics/blacksmith/male_blacksmith_6.png',
  '/images/profilePics/blacksmith/male_blacksmith_7.png',
  '/images/profilePics/blacksmith/male_blacksmith_8.png',
  '/images/profilePics/blacksmith/male_blacksmith_9.png',
  '/images/profilePics/blacksmith/male_blacksmith_10.png',
  '/images/profilePics/blacksmith/male_blacksmith_11.png',
  '/images/profilePics/blacksmith/male_blacksmith_12.png',
  '/images/profilePics/blacksmith/male_blacksmith_13.png',
  '/images/profilePics/blacksmith/male_blacksmith_14.png',
  '/images/profilePics/blacksmith/male_blacksmith_15.png',
  '/images/profilePics/blacksmith/male_blacksmith_16.png',
  '/images/profilePics/blacksmith/male_blacksmith_17.png',
  '/images/profilePics/blacksmith/male_blacksmith_18.png',
  '/images/profilePics/blacksmith/male_blacksmith_19.png',
  '/images/profilePics/blacksmith/male_blacksmith_20.png',
  '/images/profilePics/blacksmith/male_blacksmith_21.png',
  '/images/profilePics/blacksmith/male_blacksmith_22.png',
  '/images/profilePics/blacksmith/male_blacksmith_23.png',
  '/images/profilePics/blacksmith/male_blacksmith_24.png',
  '/images/profilePics/blacksmith/male_blacksmith_25.png',
  // Spy
  '/images/profilePics/spy/female_spy_19.png',
  '/images/profilePics/spy/female_spy_20.png',
  '/images/profilePics/spy/female_spy_21.png',
  '/images/profilePics/spy/female_spy_22.png',
  '/images/profilePics/spy/female_spy_23.png',
  '/images/profilePics/spy/female_spy_24.png',
  '/images/profilePics/spy/female_spy_25.png',
  '/images/profilePics/spy/male_spy_17.png',
  '/images/profilePics/spy/male_spy_18.png',
  '/images/profilePics/spy/male_spy_19.png',
  '/images/profilePics/spy/male_spy_20.png',
  '/images/profilePics/spy/male_spy_21.png',
  '/images/profilePics/spy/male_spy_22.png',
  '/images/profilePics/spy/male_spy_23.png',
  '/images/profilePics/spy/male_spy_24.png',
  '/images/profilePics/spy/male_spy_25.png',
];
