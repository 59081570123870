import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';


interface Props {
  children: any
}

export default function Layout({ children }: Props) {
  return (
    <>
      <Navbar />
      { children }
      {location.pathname !== '/admin' && <Footer />}
    </>
  );
}
