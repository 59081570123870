import api from '../config/axios';


// GET Routes

export const getConditions = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/condition', auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
