import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface CultureTraitsState {
  cultureTraits: CultureTrait[]
}

const initialState: CultureTraitsState = {
  cultureTraits: [{
    id: 0,
    trait: '',
    culture: '',
    type: 'positive',
  }],
};

export const blessingsSlice = createSlice({
  name: 'cultureTraits',
  initialState,
  reducers: {
    setCultureTraitsData: (state, action) => { state.cultureTraits = action.payload; },
  }
});

export const {
  setCultureTraitsData,
} = blessingsSlice.actions;

export const fetchCultureTraitsData = (state: RootState): CultureTrait[] => state.cultureTraits.cultureTraits;

export default blessingsSlice.reducer;
