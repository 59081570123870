import api from '../config/axios';


// GET Routes

export const getAllBlessings = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/blessing', auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editBlessing = async (blessing: Blessing) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/blessing', blessing, auth);
  } catch (error) {
    console.log(error);
  }
};

// POST Routes

export const addBlessing = async (blessing: Blessing) => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/blessing', blessing, auth);
  } catch (error) {
    console.log(error);
  }
};

// DELETE Routes

export const deleteBlessing = async (index: string) => {
  try {
    const auth = { withCredentials: true };
    await api.delete(`/api/blessing/${index}`, auth);
  } catch (error) {
    console.log(error);
  }
};
