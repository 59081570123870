import axios from 'axios';

const getUrl = () => {
  if (window.location.host.split(':')[0] === 'localhost') {
    return 'http://localhost:8888';
  } else {
    return 'https://ffg-server.up.railway.app';
  }
};

const baseURL = getUrl();
const api = axios.create({
  baseURL,
});

export default api;
