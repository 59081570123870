import React, { FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchFeaturedContentState, setFeaturedContentsData } from "../../redux/reducers/featuredContentSlice";
import { deleteImageFirebase, uploadImageFirebase } from "../../scripts/tools/firebaseUtils";
import { editFeaturedContent } from "../../scripts/controllers/featuredContentController";


export default function AdminFeaturedBox() {
  const dispatch = useAppDispatch();
  const featuredContentState: FeaturedContent = useAppSelector(fetchFeaturedContentState);
  const [featureBoxImgInputs, setFeatureBoxImgInputs] = useState<any[]>([]);
  const [newImageInput, setNewImageInput] = useState<any>();
  const [featureBoxImgLoading, setFeatureBoxImgLoading] = useState(false);
  

  const handleAddImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImage = e.target.files[0];
      setNewImageInput(newImage);
  
      // Preview image
      const reader = new FileReader();
      reader.onload = function (e) {
        const imagePreview = document.getElementById('new-featured-image-input') as HTMLImageElement;
        if (imagePreview) {
          imagePreview.src = e.target?.result as string;
        }
      };
      reader.readAsDataURL(newImage);
    }
  };  

  const handleImageInputChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImages = Array.from(e.target.files);
      setFeatureBoxImgInputs((prevImages) => [...prevImages, ...newImages]);

      // Preview image
      const reader = new FileReader();
      reader.onload = function(e) {
        (document.getElementById(`featured-image-input-${i}`) as any).src = e.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleEditFeaturedContent = async (e: FormEvent) => {
    e.preventDefault();
  
    if (featureBoxImgInputs.length > 0 || newImageInput) {
      setFeatureBoxImgLoading(true);
  
      try {
        const existingImageUrls = await Promise.all(
          featureBoxImgInputs.map(
            (image) =>
              new Promise<string>((resolve) => {
                if (image) {
                  uploadImageFirebase(image, (url: string) => {
                    resolve(url);
                  });
                }
              })
          )
        );

        const newImageUrl = newImageInput
          ? await new Promise<string>((resolve) => {
            if (newImageInput instanceof File) {
              uploadImageFirebase(newImageInput, (url: string) => {
                resolve(url);
              });
            } else {
              resolve(newImageInput);
            }
          })
          : null;
  
        // Create a copy of the existing array
        const updatedImgArray = [...featuredContentState.featureBoxImgArray];

        // Replace the existing images with the new ones
        existingImageUrls.forEach((url, index) => {
          updatedImgArray[index] = url;
        });

        if (newImageUrl) {
          updatedImgArray.push(newImageUrl);
        }
  
        dispatch(setFeaturedContentsData({ ...featuredContentState, featureBoxImgArray: updatedImgArray }));
        await editFeaturedContent({ ...featuredContentState, featureBoxImgArray: JSON.stringify(updatedImgArray) as any });
        window.location.href = '/admin?s=featured box';
      } catch (error) {
        console.error('Error uploading images:', error);
      } finally {
        setFeatureBoxImgLoading(false);
      }
    } else {
      console.error('No image selected');
    }
  };

  const removeFeaturedImage = async (index: number) => {
    if (!confirm("Are you sure?")) return;

    try {
      const fileName = featuredContentState.featureBoxImgArray[index].split('/o/')[1].split('?alt')[0];
      await deleteImageFirebase(fileName);
  
      // Create a copy of the existing array and remove the image at the specified index
      const updatedImgArray = [...featuredContentState.featureBoxImgArray];
      updatedImgArray.splice(index, 1);

      dispatch(setFeaturedContentsData({ ...featuredContentState, featureBoxImgArray: updatedImgArray }));
      await editFeaturedContent({ ...featuredContentState, featureBoxImgArray: JSON.stringify(updatedImgArray) as any });
    } catch (error) {
      console.error('Error removing image:', error);
    }
  };
  

  return (
    <div className="admin-page__featured-box">
      <form onSubmit={(e) => handleEditFeaturedContent(e)} className="admin-page__featured-box-form">
        <div className="admin-page__featured-box-form--top-buttons">
          <button type="submit">Save</button>
        </div>
        {featureBoxImgLoading ?
          <p>Loading...</p>
          :
          <>
            <div className="admin-page__featured-image-container">
              {featuredContentState.featureBoxImgArray.map((img, i) => {
                return (
                  <div key={i} className="admin-page__featured-image-edit">
                    <button type="button" onClick={() => removeFeaturedImage(i)}>Remove Image</button>
                    {!img &&
                      <label>
                        Featured Image
                        <input
                          type="file"
                          onChange={(e) => handleImageInputChange(e, i)}
                          accept="image/*"
                        />
                      </label>
                    }
                    <img
                      src={img}
                      alt={`Featured image ${i}`}
                      id={`featured-image-input-${i}`}
                    />
                  </div>
                );
              })}
            </div>

            <br />
            <div className="admin-page__featured-image-edit">
              <label className="admin-page__add-featured-image-btn">
                Add Image
                <input
                  type="file"
                  onChange={handleAddImageInputChange}
                  accept="image/*"
                />
              </label>
              <img
                alt={'New featured image input'}
                id={'new-featured-image-input'}
              />
            </div>
          </>
        }
      </form>
    </div>
  );
}
