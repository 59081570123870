import React from "react";
import { characterStateHasIndex, determineClassStartingSilver } from "../../../scripts/characterSheetLogic";
import { useAppDispatch } from "../../../redux/hooks";
import { setCharacterSp } from "../../../redux/reducers/characterSlice";


interface Props {
  closeModal: () => void
  characterState: Character
  rolledSilver: boolean
  setRolledSilver: (value: boolean) => void
  setGearOrSilver: (value: string) => void
}

export default function PickStartingSilver({ closeModal, characterState, rolledSilver, setRolledSilver, setGearOrSilver }: Props) {
  const dispatch = useAppDispatch();
  
  const rollStartingSilver = () => {
    setRolledSilver(true);
    const HAS_WEALTHY_BLESSING = characterStateHasIndex(characterState.blessings, 'wealthy');
    const roll = determineClassStartingSilver(characterState.characterClass);
    if (HAS_WEALTHY_BLESSING) {
      dispatch(setCharacterSp(roll * 2));
    } else {
      dispatch(setCharacterSp(roll));
    }
  };


  return (
    <div className="dropdown-modal">
      <h2>Roll Starting Silver</h2>
      {rolledSilver ?
        <>
          <p>SP: { characterState.sp }</p>
        </>
        :
        <button onClick={rollStartingSilver}>Roll{ characterStateHasIndex(characterState.blessings, 'wealthy') &&  <span style={{color: 'var(--blue-1)'}}> + wealthy blessing</span> }</button>
      }
      <div className="dropdown-modal--bottom">
        <button type="button" onClick={() => setGearOrSilver('')}>
          <img
            src="/images/buttons/back_button.png"
            alt="back button"
          />
        </button>
        {rolledSilver &&
          <button onClick={closeModal}>
            <img
              src="/images/buttons/next_button.png"
              alt="next button"
            />
          </button>
        }
      </div>
    </div>
  );
}
