import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface FeaturedContentState {
  featuredContentState: FeaturedContent
  smallFeaturedContentState: SmallFeaturedContent
}

const initialState: FeaturedContentState = {
  featuredContentState: {
    featureBoxImgArray: [],
  },
  smallFeaturedContentState: {
    featureBoxImg1: '',
    featureBoxImg2: '',
    featureBoxImg3: '',
    featureBoxImg4: '',
  },
};

export const featuredContentSlice = createSlice({
  name: 'feature-content',
  initialState,
  reducers: {
    setFeaturedContentsData: (state, action) => { state.featuredContentState = action.payload; },
    setSmallFeaturedContentsData: (state, action) => { state.smallFeaturedContentState = action.payload; },
  }
});

export const {
  setFeaturedContentsData,
  setSmallFeaturedContentsData,
} = featuredContentSlice.actions;

export const fetchFeaturedContentState = (state: RootState): FeaturedContent => state.featuredContent.featuredContentState;
export const fetchSmallFeaturedContentState = (state: RootState): SmallFeaturedContent => state.featuredContent.smallFeaturedContentState;

export default featuredContentSlice.reducer;
