export const capitalize = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.replace(str.charAt(0), '')}`;
};

export const getFirstTwoLettersOfDmg = (strings: string[]): string[] => {
  return strings.map((str: string) => {
    if (str === 'bludgeoning' || str === 'piercing' || str === 'slashing') {
      return str.slice(0, 2);
    } else {
      return str;
    }
  });
};
