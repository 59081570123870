import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface FeaturesState {
  features: FeatureDisplay[]
}

const initialState: FeaturesState = {
  features: [{
    index: '',
    title: '',
    desc: '',
    level: 0,
    class: '',
    featureList: []
  }],
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeaturesData: (state, action) => { state.features = action.payload; },
  }
});

export const {
  setFeaturesData,
} = featuresSlice.actions;

export const fetchFeaturesState = (state: RootState): FeatureDisplay[] => state.features.features;

export default featuresSlice.reducer;