import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Modal from './components/Modals/Modal';
import PerkOrSkillModal from './components/Modals/LevelUp/PerkOrSkillModal';
import ClassFeaturesModal from './components/Modals/LevelUp/ClassFeaturesModal';
import { getUser } from './scripts/controllers/userController';
import WeaponModifierModal from './components/Modals/LevelUp/WeaponModifierModal';
import NewCharacterModal from './components/Modals/NewCharacter/NewCharacterModal';
import BlessingsModal from './components/Modals/LevelUp/BlessingsModal';
import './styles/index.scss';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


let user;
const fetchAppData = async () => {
  user = await getUser();
  

  root.render(
    <Provider store={store}>
      <App user={user} />
      <div id="saved-anim" className="saved-anim">
        <p>Saved!</p>
      </div>
      <div id="desc-box" className="desc-box hidden"><p></p></div>
      {/* Modals */}
      {user &&
        <>
          <Modal index={'new-character-modal'} canExit={false} className="new-character-modal">
            <NewCharacterModal index={'new-character-modal'} />
          </Modal>
          <Modal index={'perk-or-skill-modal'} canExit={false}>
            <PerkOrSkillModal index={'perk-or-skill-modal'} />
          </Modal>
          <Modal index={'blessings-modal'} canExit={false}>
            <BlessingsModal index={'blessings-modal'} />
          </Modal>
          <Modal index={'weapon-modifier-modal'} canExit={false}>
            <WeaponModifierModal index={'weapon-modifier-modal'} />
          </Modal>
          <Modal index={'features-modal'} width={50} height={40} canExit={false}>
            <ClassFeaturesModal index={'features-modal'} />
          </Modal>
        </>
      }
    </Provider>
  );
};

fetchAppData();

