import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterFeatures, setCharacterWeaponMod } from "../../../redux/reducers/characterSlice";
import { rollDice } from "../../../scripts/diceRolls";


interface Props {
  index: string
}

export default function WeaponModifierModal({ index }: Props) {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const [rolledDice, setRolledDice] = useState(false);
  const [rollAmount, setRollAmount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  

  const closeModal = () => {
    setRolledDice(false);
    setPageNumber(1);
    document.getElementById(`modal-container-${index}`).classList.add('hidden');
  };

  const rollWeaponMod = () => {
    setRolledDice(true);
    const roll = rollDice(1, 4).total;
    setRollAmount(roll);
    const newMod: number = characterState.weaponExhaustionMod + roll;
    dispatch(
      setCharacterWeaponMod(newMod)
    );
  };

  const addExtraAttack = () => {
    setPageNumber(2);
    let hasExtraAttack = false;
    const updatedFeatures = characterState.features.map((feature: Feature) => {
      if (feature.index === 'extra-attack') {
        hasExtraAttack = true;
        return { index: 'extra-attack', name: 'Extra Attack x2', desc: 'Make 2 more attacks' };
      } else {
        return feature;
      }
    });
    if (!hasExtraAttack) {
      updatedFeatures.push({ index: 'extra-attack', name: 'Extra Attack', desc: 'Make another attack' });
    }

    dispatch(
      setCharacterFeatures([
        ...updatedFeatures
      ])
    );
  };

  return (
    <div className="level-up-modal weapon-modifier-modal">
      {pageNumber === 1 &&
        <>
          <h2>New Feature: Extra Attack</h2>
          <button onClick={addExtraAttack}>Next</button>
        </>
      }
      {pageNumber === 2 &&
        <>
          <h2>Roll for Weapon Exhaustion Modifier</h2>
          {rolledDice ?
            <>
              <p>Rolled: { rollAmount }</p>
              <p>New Total: { characterState.weaponExhaustionMod }</p>
              <button onClick={closeModal}>Done</button>
            </>
            :
            <button onClick={rollWeaponMod}>Roll</button>
          }
        </>
      }
    </div>
  );
}
