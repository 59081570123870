import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchCharacterState, setCharacterExhaustion, setCharacterLevel, setCharacterName } from '../../../redux/reducers/characterSlice';
import { characterStateHasIndex, determineClassStartingRestDice, determinePlayerDailySlotsAvailable } from '../../../scripts/characterSheetLogic';
import { rollDice } from '../../../scripts/diceRolls';


interface Props {
  levelUpBtn: HTMLButtonElement
}

export default function SheetTopBlock({ levelUpBtn }: Props) {
  const dispatch = useAppDispatch();
  const characterState = useAppSelector(fetchCharacterState);
  const slotsAvailable = determinePlayerDailySlotsAvailable(characterState.level);

  useEffect(() => {
    if (characterState.level === 0) {
      document.getElementById('modal-container-new-character-modal').classList.remove('hidden');
    }
  }, []);

  if (levelUpBtn) levelUpBtn.onclick = () => handleLevelUp();

  const getDefenseMod = (): number => {
    let defenseMod = 0;
    characterState.gear.forEach((gear: Gear) => {
      if (gear.type === 'armor') defenseMod += gear.defense;
    });
    return defenseMod;
  };

  const handleLevelUp = () => {
    const level = characterState.level + 1;
    if (level > 10) return;
    dispatch(setCharacterLevel(level));
    if (characterState.level > level) return;
    const PERK_OR_SKILL_LEVEL: boolean = level % 2 === 0;
    const FEATURE_LEVEL: boolean = level === 3 || level === 5 || level === 7 || level === 10;
    const WEAPON_MOD_LEVEL: boolean = level === 4 || level === 8;
    const BLESSING_LEVEL: boolean = level === 5 || level === 10;
    const HAS_HARDENED_BLESSING = characterStateHasIndex(characterState.blessings, 'hardened');
    const { amount, type } = determineClassStartingRestDice(characterState.characterClass);
    
    if (HAS_HARDENED_BLESSING) {
      dispatch(setCharacterExhaustion(characterState.exhaustion + (amount * type)));
    } else {
      dispatch(setCharacterExhaustion(characterState.exhaustion + rollDice(amount, type).total));
    }

    if (PERK_OR_SKILL_LEVEL) {
      document.getElementById('modal-container-perk-or-skill-modal').classList.remove('hidden');
    }
    if (FEATURE_LEVEL) {
      document.getElementById('modal-container-features-modal').classList.remove('hidden');
    }
    if (WEAPON_MOD_LEVEL) {
      document.getElementById('modal-container-weapon-modifier-modal').classList.remove('hidden');
    }
    if (BLESSING_LEVEL) {
      document.getElementById('modal-container-blessings-modal').classList.remove('hidden');
    }
  };


  return (
    <div className="character-sheet__top-block">
      <input
        className="character-sheet__field character-sheet__top-block--name"
        placeholder="NAME"
        value={characterState.name}
        onChange={(e) => dispatch(setCharacterName(e.target.value))}
      />
      <div className="character-sheet__field character-sheet__top-block--rest-dice">
        <p>REST DICE: <span>{ characterState.restDice.display.toUpperCase() }</span></p>
      </div>
      <div className="character-sheet__field character-sheet__top-block--rest-dice-value"></div>
      
      <div className="character-sheet__field character-sheet__top-block--class">
        <p>CLASS: <span style={{ "color":"var(--dark-red-0)" }}>{ characterState.characterClass.toUpperCase() }</span></p>
      </div>
      <div className="character-sheet__field character-sheet__top-block--breathers-taken">
        <p style={{ "display":"flex" }}>BREATHERS TAKEN: <span style={{ "display":"flex" }}><span className="breathers-taken-circle"></span><span className="breathers-taken-circle"></span><span className="breathers-taken-circle"></span></span></p>
      </div>

      <div className="character-sheet__field character-sheet__top-block--level">
        <p>LEVEL: { characterState.level }</p>
      </div>
      <div className="character-sheet__field character-sheet__top-block--speed">
        <p>SPEED: { characterStateHasIndex(characterState.blessings, 'quick') ? characterState.speed + 10 : characterState.speed }</p>
      </div>
      <div className="character-sheet__field character-sheet__top-block--faith-points">
        <p>FAITH POINTS:</p>
      </div>
      {/* <input
        className="character-sheet__field character-sheet__top-block--age"
        placeholder="AGE"
        value={characterState.age === 0 ? '' : characterState.age}
        onChange={(e) => dispatch(setCharacterAge(Number(e.target.value)))}
        type="number"
      /> */}
      {/* <Dropdown
        classData="character-sheet__field character-sheet__top-block--gender"
        emptyValueText="-- PICK A GENDER --"
        inputChange={(value: string) => dispatch(setCharacterGender(value))}
        value={{ index: characterState.gender, name: characterState.gender.toUpperCase() }}
        options={[
          { index: 'male', name: 'MALE' },
          { index: 'female', name: 'FEMALE' }
        ]}
      /> */}
      {/* <div className="character-sheet__field character-sheet__top-block--weapon-exhaustion-mod">
        <p>WEAPON EXHAUSTION MODIFIER: { characterState.weaponExhaustionMod }</p>
      </div> */}
      {/* <div className="character-sheet__field character-sheet__top-block--attack-dice">
        <p>ATTACK DICE: <span>D12 + { characterState.characterClass === 'blacksmith' || characterState.characterClass === 'brawler' ? characterState.physical.value : characterState.technical.value }</span></p>
      </div>
      <div className="character-sheet__field character-sheet__top-block--defense-dice">
        <p>DEFENSE DICE: <span>D12 + { getDefenseMod() }</span></p>
      </div> */}

      {/* <div className="character-sheet__field character-sheet__top-block--culture">
        <p>CULTURE: { characterState.culture.toUpperCase() }</p>
      </div> */}
    </div>
  );
}
