import React, { FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterBlessings } from "../../../redux/reducers/characterSlice";
import { fetchBlessingsState } from "../../../redux/reducers/blessingsSlice";
import Dropdown from "../../Dropdowns/Dropdown";
import { characterStateHasIndex } from "../../../scripts/characterSheetLogic";


interface Props {
  index: string
}

export default function BlessingsModal({ index }: Props) {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const blessingsState: Blessing[] = useAppSelector(fetchBlessingsState);
  const [selectedBlessing, setSelectedBlessing] = useState('anoited');
  

  const closeModal = () => {
    setSelectedBlessing('');
    document.getElementById(`modal-container-${index}`).classList.add('hidden');
  };

  const handleSaveChanges = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      setCharacterBlessings([
        ...characterState.blessings,
        blessingsState.find((blessing: Blessing) => blessing.index === selectedBlessing)
      ])
    );
    closeModal();
  };
  

  return (
    <div className="level-up-modal blessings-modal">
      <form onSubmit={(e) => handleSaveChanges(e)}>
        <h2>CHOOSE A BLESSING</h2>
        <Dropdown
          classData="level-up-modal__dropdown"
          emptyValueText={selectedBlessing}
          inputChange={(value: string) => setSelectedBlessing(value)}
          value={blessingsState.find((blessing: Blessing) => blessing.index === selectedBlessing)}
          options={blessingsState.map((blessing: Blessing) => {
            if (characterState.id && !characterStateHasIndex(characterState.blessings, blessing.index)) {
              return { index: blessing.index, name: blessing.name, hoverContent: { title: blessing.name, desc: blessing.desc }};
            } else {
              return null;
            }
          })}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
