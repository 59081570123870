import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState } from "../../../redux/reducers/characterSlice";
import { fetchFeaturesState } from "../../../redux/reducers/featuresSlice";
import { determinePlayerDailySlotsAvailable } from "../../../scripts/characterSheetLogic";


export default function SheetFeaturesBlock() {
  const characterState: Character = useAppSelector(fetchCharacterState);
  const featuresState: FeatureDisplay[] = (useAppSelector(fetchFeaturesState)).filter((feature: FeatureDisplay) => {
    return feature.class === characterState.characterClass;
  });
  const [blankRows, setBlankRows] = useState([]);
  const slotsAvailable = determinePlayerDailySlotsAvailable(characterState.level);
  
  useEffect(() => {
    getBlankRows();
  }, [characterState]);
  
  // Get the amount of empty rows to render
  const getBlankRows = () => {
    const numOfBlankRows = (characterState.features[0] !== null ? 5 : 6) - characterState.features.length;
    const emptyRows = [];
    for (let i = 0; i < numOfBlankRows; i++) {
      emptyRows.push([...blankRows, i]);
    }
    setBlankRows(emptyRows);
  };


  return (
    <>
      <div className="character-sheet__daily-slots-container">
        <div>
          <p>DAILY SLOTS AVAILABLE:</p>
        </div>
        <div className="character-sheet__daily-slots-circles">
          <span className={`daily-slot-circle daily-slot-circle${slotsAvailable >= 1 && '--filled'}`}></span>
          <span className={`daily-slot-circle daily-slot-circle${slotsAvailable >= 2 && '--filled'}`}></span>
          <span className={`daily-slot-circle daily-slot-circle${slotsAvailable >= 3 && '--filled'}`}></span>
          <span className={`daily-slot-circle daily-slot-circle${slotsAvailable >= 4 && '--filled'}`}></span>
          <span className={`daily-slot-circle daily-slot-circle${slotsAvailable >= 5 && '--filled'}`}></span>
        </div>
      </div>
      
      <div className="character-sheet__features">
        {/* Features header */}
        <div className="character-sheet__field character-sheet__features-header character-sheet__features-header--name-title">
          <p>Class Trait Name</p>
        </div>
        <div className="character-sheet__field character-sheet__features-header character-sheet__features-header--range-title">
          <p>Range</p>
        </div>
        <div className="character-sheet__field character-sheet__features-header character-sheet__features-header--type-title">
          <p>Type</p>
        </div>
        <div className="character-sheet__field character-sheet__features-header character-sheet__features-header--exhaustion-title">
          <p>Exhaustion Inflicted</p>
        </div>

        {/* Features content */}
        {characterState.features[0] !== null && characterState.features.filter((feature: Feature) => feature.index !== 'extra-attack').map((feature: Feature, i) => {     
          return (
            <React.Fragment key={i}>
              {/* "i + 2" is to put it under the titles */}
              <div className={`character-sheet__field character-sheet__features--level character-sheet__features--level-${i + 2}`}>
                <p>{ feature.level }</p>
              </div>
              <div className={`character-sheet__field character-sheet__features--name-${i + 2}`}>
                <p>{ feature.name }</p>
              </div>
              <div className={`character-sheet__field character-sheet__features--range-${i + 2}`}>
                {feature.range && feature.range.min > 0 ?
                  <p>{ feature.range.min }{ feature.range.max && `/${feature.range.max}` }</p>
                  :
                  <p>melee</p>
                }
              </div>
              <div className={`character-sheet__field character-sheet__features--type-${i + 2}`}>
                <p>{ feature.type && feature.type }</p>
              </div>
              <div className={`character-sheet__field character-sheet__features--exhaustion-${i + 2}`}>
                <p>{feature.exhaustionInflicted && feature.exhaustionInflicted.dice.display } { feature.exhaustionInflicted && feature.exhaustionInflicted.types }</p>
              </div>
              <div className={`character-sheet__field character-sheet__features--desc-${i + 2}`}>
                <p>{ feature.desc }</p>
              </div>
            </React.Fragment>
          );
        })}

        {/* Blank rows */}
        {blankRows.map((row, i) => {
          const newFilteredFeatures = characterState.features.filter((feature: Feature) => feature && feature.index !== 'extra-attack');
          const length = newFilteredFeatures.length + 2;
          const currentFeature: FeatureDisplay = featuresState[i + newFilteredFeatures.length];
            
          return (
            <React.Fragment key={i}>
              <div className={`character-sheet__field character-sheet__features--level character-sheet__features--level-${i + length}`}><p>{ currentFeature && currentFeature.featureList.length > 0 && currentFeature.featureList[0].level }</p></div>
              <div className={`character-sheet__field character-sheet__features--name-${i + length}`}></div>
              <div className={`character-sheet__field character-sheet__features--range-${i + length}`}></div>
              <div className={`character-sheet__field character-sheet__features--type-${i + length}`}></div>
              <div className={`character-sheet__field character-sheet__features--exhaustion-${i + length}`}></div>
              <div className={`character-sheet__field character-sheet__features--desc-${i + length}`}></div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}
