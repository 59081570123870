import api from '../config/axios';


// GET Routes

export const getAllSpecialAmmo = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/ammo', auth);
    return res.data.map((ammo: any) => {
      return { ...ammo, cost: JSON.parse(ammo.cost) };
    });
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editSpecializedAmmo = async (ammo: SpecializedAmmo) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/ammo', ammo, auth);
  } catch (error) {
    console.log(error);
  }
};

// POST Routes

export const addSpecializedAmmo = async (ammo: SpecializedAmmo) => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/ammo', ammo, auth);
  } catch (error) {
    console.log(error);
  }
};

// DELETE Routes

export const deleteSpecializedAmmo = async (id: number) => {
  try {
    const auth = { withCredentials: true };
    await api.delete(`/api/ammo/${id}`, auth);
  } catch (error) {
    console.log(error);
  }
};
