import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterPerks, setCharacterSkills } from "../../../redux/reducers/characterSlice";
import { fetchPerksState } from "../../../redux/reducers/perksSlice";
import Dropdown from "../../Dropdowns/Dropdown";


interface Props {
  index: string;
}

export default function PerkOrSkillModal({ index }: Props) {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const perksState: Perk[] = useAppSelector(fetchPerksState);
  const [optionChosen, setOptionChosen] = useState("");
  const [selectedPerk, setSelectedPerk] = useState(perksState[0]);
  const [selectedSkill, setSelectedSkill] = useState<Skill>();

  const languages = perksState.filter((_perk: Perk) => _perk.parent === 'language');
  const tools = perksState.filter((_perk: Perk) => _perk.parent === 'tool');
  const instruments = perksState.filter((_perk: Perk) => _perk.parent === 'instrument');
  

  useEffect(() => {
    setOptionChosen("");
    setSelectedSkill(characterState.skills && characterState.skills[0]);
  }, [characterState]);

  const closeModal = () => {
    document.getElementById(`modal-container-${index}`).classList.add("hidden");
  };

  const handleSaveChanges = (e: FormEvent) => {
    e.preventDefault();
    if (!selectedPerk && !selectedSkill) return;

    if (optionChosen === "perk") {
      dispatch(setCharacterPerks([...characterState.perks, selectedPerk]));
    } else {
      dispatch(
        setCharacterSkills(
          characterState.skills.map((skill: Skill) => {
            if (skill === selectedSkill) {
              return { name: selectedSkill.name, type: selectedSkill.type, bonus: selectedSkill.bonus + 1 };
            } else {
              return skill;
            }
          })
        )
      );
    }
    setOptionChosen("");
    closeModal();
  };

  return (
    <div className="level-up-modal perk-or-skill-modal">
      {optionChosen === "perk" && (
        <form onSubmit={(e) => handleSaveChanges(e)}>
          <h2>CHOOSE A PERK</h2>
          <Dropdown
            classData="level-up-modal__dropdown"
            emptyValueText={perksState[0].desc}
            inputChange={(value: string) => setSelectedPerk(perksState.find((perk: Perk) => perk.desc.includes(value)))}
            value={{ index: selectedPerk.desc, name: selectedPerk.desc }}
            options={[
              {index: 'Learn a new language', name: 'Learn a new language', subOptions: languages.map((_perk: Perk) => {
                return { index: _perk.desc, name: _perk.desc };
              })},
              {index: 'Learn a new tool', name: 'Learn a new tool', subOptions: tools.map((_perk: Perk) => {
                return { index: _perk.desc, name: _perk.desc };
              })},
              {index: 'Learn a new instrument', name: 'Learn a new instrument', subOptions: instruments.map((_perk: Perk) => {
                return { index: _perk.desc, name: _perk.desc };
              })},
              ...perksState.map((perk: Perk) => {
                if (!perk.parent) return { index: perk.desc, name: perk.desc };
              })
            ]}
          />
          <button type="submit">Submit</button>
        </form>
      )}

      {optionChosen === "skill" && (
        <form onSubmit={(e) => handleSaveChanges(e)}>
          <h2>CHOOSE A SKILL</h2>
          <select
            onChange={(e) => {
              const selectedSkill = characterState.skills.find((skill: Skill) => skill.name === e.target.value);
              setSelectedSkill(selectedSkill);
            }}
          >
            {characterState.skills.map((skill: Skill) => {
              return (
                <option key={skill.name} value={skill.name}>
                  {skill.name.toUpperCase()}
                </option>
              );
            })}
          </select>
          <button type="submit">Submit</button>
        </form>
      )}

      {optionChosen === "" ? (
        <>
          <h2>CHOOSE PERK OR +1 SKILL POINT</h2>
          <button onClick={() => setOptionChosen("perk")}>NEW PERK</button>
          <button onClick={() => setOptionChosen("skill")}>+1 SKILL POINT</button>
        </>
      ) : (
        <button className="level-up-modal__back-btn" onClick={() => setOptionChosen("")}>
          Back
        </button>
      )}
    </div>
  );
}
