import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { storage } from "../config/firebase";
import { generateUniqueID } from "./utils";


export const uploadImageFirebase = async (imageFile: File, fn?: any) => {
  const randChar = generateUniqueID();
  const name = imageFile.name.split('.');
  const imageRef = ref(storage, `[${name[0]}-${randChar}.${name[1]}]`);
  
  await uploadBytes(imageRef, imageFile).then((snapshot) => { 
    fn(`https://firebasestorage.googleapis.com/v0/b/antediluvian-db.appspot.com/o/${snapshot.metadata.name}?alt=media`);
  }).catch((error) => {
    console.error('Error uploading image: ', error);
  });
};

export const deleteImageFirebase = async (url: string) => {
  if (!url.includes('[') || !url.includes(']')) return;
  const name = `[${url.split('[')[1].split(']')[0]}]`;
  const imageRef = ref(storage, name);
  deleteObject(imageRef).catch((error) => {
    console.error('Error deleting image: ', error);
  });
};
