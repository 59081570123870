import React, { useEffect, useRef, useState } from "react";


interface Props {
  children: any
  index: string
  className?: string
  width?: number
  height?: number
  canExit: boolean
}

export default function Modal({ children, index, className, width, height, canExit }: Props) {
  const [reload, setReload] = useState(0);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    modalRef.current.style.setProperty('max-width', `${width}rem`);
    modalRef.current.style.setProperty('max-height', `${height}rem`);
  }, [reload]);

  const closeModal = () => {
    if (!canExit) return;
    setReload(reload + 1);
    document.getElementById(`modal-container-${index}`).classList.add('hidden');
  };


  return (
    <div id={`modal-container-${index}`} className="hidden">
      <div className="modal-overlay" onClick={closeModal}></div>

      <div className={`${className ? className : 'modal'}`} ref={modalRef}>
        {canExit &&
          <button className="modal__delete-btn" onClick={closeModal} tabIndex={1}>X</button>
        }
        { children }
      </div>
    </div>
  );
}
