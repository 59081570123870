import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface WeaponsState {
  weapons: Weapon[]
}

const initialState: WeaponsState = {
  weapons: [{
    id: 0,
    weaponId: 0,
    index: '',
    name: '',
    desc: '',
    range: { min: 0, max: 0 },
    properties: '',
    exhaustionInflicted: [{ types: [], dice: { amount: 0, type: 0, display: '' }}],
    lbs: 0,
    cost: { amount: 0, currency: '' },
    qty: 0,
    carried: false,
    canHaveSpecialAmmo: false,
  }],
};

export const weaponsSlice = createSlice({
  name: 'weapons',
  initialState,
  reducers: {
    setWeaponsData: (state, action) => { state.weapons = action.payload; },
  }
});

export const {
  setWeaponsData,
} = weaponsSlice.actions;

export const fetchWeaponsState = (state: RootState): Weapon[] => state.weapons.weapons;

export default weaponsSlice.reducer;