import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface LanguagesState {
  languages: Language[]
}

const initialState: LanguagesState = {
  languages: [{
    id: 0,
    index: '',
    name: '',
    culture: '',
    territory: '',
  }],
};

export const languagesSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguagesData: (state, action) => { state.languages = action.payload; },
  }
});

export const {
  setLanguagesData,
} = languagesSlice.actions;

export const fetchLanguagesState = (state: RootState): Language[] => state.languages.languages;

export default languagesSlice.reducer;
