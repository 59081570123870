import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface SpecializedAmmoState {
  specializedAmmo: SpecializedAmmo[]
}

const initialState: SpecializedAmmoState = {
  specializedAmmo: [{
    id: 0,
    name: '',
    desc: '',
    cost: null,
    lbs: 0,
    aoe: 0,
    type: '',
  }],
};

export const specializedAmmoSlice = createSlice({
  name: 'specializedAmmo',
  initialState,
  reducers: {
    setSpecializedAmmoData: (state, action) => { state.specializedAmmo = action.payload; },
  }
});

export const {
  setSpecializedAmmoData,
} = specializedAmmoSlice.actions;

export const fetchSpecializedAmmoState = (state: RootState): SpecializedAmmo[] => state.specializedAmmo.specializedAmmo;

export default specializedAmmoSlice.reducer;
