import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface BlessingsState {
  blessings: Blessing[]
}

const initialState: BlessingsState = {
  blessings: [{
    index: '',
    name: '',
    desc: '',
  }],
};

export const blessingsSlice = createSlice({
  name: 'blessing',
  initialState,
  reducers: {
    setBlessingsData: (state, action) => { state.blessings = action.payload; },
  }
});

export const {
  setBlessingsData,
} = blessingsSlice.actions;

export const fetchBlessingsState = (state: RootState): Blessing[] => state.blessings.blessings;

export default blessingsSlice.reducer;