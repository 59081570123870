import React, { useState } from "react";
import Layout from "../components/Layout";
import AdminFeatures from "../components/AdminPage/AdminFeatures";
import AdminBlessings from "../components/AdminPage/AdminBlessings";
import AdminPerks from "../components/AdminPage/AdminPerks";
import AdminLanguages from "../components/AdminPage/AdminLanguages";
import AdminGear from "../components/AdminPage/AdminGear";
import AdminWeapons from "../components/AdminPage/AdminWeapons";
import AdminArmor from "../components/AdminPage/AdminArmor";
import AdminFeaturedBox from "../components/AdminPage/AdminFeaturedBox";
import AdminSmallFeaturedBoxes from "../components/AdminPage/AdminSmallFeatureBoxes";
import AdminSpecialAmmo from "../components/AdminPage/AdminSpecialAmmo";
import AdminCultureTraits from "../components/AdminPage/AdminCultureTraits";


export default function AdminPage() {
  const section = new URLSearchParams(document.location.search).get('s') || '';
  const [selectedBlock, setSelectedBlock] = useState(section);


  return (
    <Layout>
      <div className="admin-page">
        <h1>{ selectedBlock === '' ? 'Select data to modify' : selectedBlock }</h1>
        {selectedBlock === '' ?
          <>
            <div className="admin-page__btn-container">
              <button onClick={() => setSelectedBlock('features')}>Features</button>
              <button onClick={() => setSelectedBlock('blessings')}>Blessings</button>
              <button onClick={() => setSelectedBlock('perks')}>Perks</button>
              <button onClick={() => setSelectedBlock('cultures')}>Culture Traits</button>
              <button onClick={() => setSelectedBlock('languages')}>Languages</button>
              <button onClick={() => setSelectedBlock('gear')}>Gear</button>
              <button onClick={() => setSelectedBlock('weapons')}>Weapons</button>
              <button onClick={() => setSelectedBlock('armor')}>Armor</button>
              <button onClick={() => setSelectedBlock('ammo')}>Specialized Ammo</button>
            </div>
            <div className="admin-page__btn-container">
              <button onClick={() => setSelectedBlock('featured box')}>Featured Box</button>
              <button onClick={() => setSelectedBlock('small featured boxes')}>Small Featured Boxes</button>
            </div>
          </>
          :
          <div className="admin-page__btn-container">
            <button onClick={() => setSelectedBlock('')}>Back</button>
          </div>
        }
        { selectedBlock === 'features' && <AdminFeatures /> }
        { selectedBlock === 'blessings' && <AdminBlessings /> }
        { selectedBlock === 'perks' && <AdminPerks /> }
        { selectedBlock === 'cultures' && <AdminCultureTraits /> }
        { selectedBlock === 'languages' && <AdminLanguages /> }
        { selectedBlock === 'gear' && <AdminGear /> }
        { selectedBlock === 'weapons' && <AdminWeapons /> }
        { selectedBlock === 'armor' && <AdminArmor /> }
        { selectedBlock === 'ammo' && <AdminSpecialAmmo /> }
        { selectedBlock === 'featured box' && <AdminFeaturedBox /> }
        { selectedBlock === 'small featured boxes' && <AdminSmallFeaturedBoxes /> }
      </div>
    </Layout>
  );
}
