import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface UserState {
  user: User
}

const initialState: UserState = {
  user: {
    id: 0,
    username: '',
    accesslevel: 0,
    profilePic: ''
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => { state.user = action.payload; },
  }
});

export const {
  setUserData,
} = userSlice.actions;

export const fetchUserState = (state: RootState): User => state.user.user;

export default userSlice.reducer;
