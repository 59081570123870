import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState } from "../../../redux/reducers/characterSlice";
import { getBonusFromAbility, getRawBonusFromAbility } from "../../../scripts/characterSheetLogic";


export default function SheetSkillsBlock() {
  const characterState: Character = useAppSelector(fetchCharacterState);


  return (
    <div className="character-sheet__skills">
      {/* Skills header */}
      <div className="character-sheet__field character-sheet__skills-header character-sheet__skills-header--name-title">
        <p>Skill Name</p>
      </div>
      <div className="character-sheet__field character-sheet__skills-header character-sheet__skills-header--roll-title">
        <p>Roll</p>
      </div>
      <div className="character-sheet__field character-sheet__skills-header character-sheet__skills-header--bonus-title">
        <p>Bns.</p>
      </div>
      <div className="character-sheet__field character-sheet__skills-header character-sheet__skills-header--total-title">
        <p>Total</p>
      </div>

      {/* Skills content */}
      {characterState.skills.map((skill: Skill, i) => {
        return (
          <React.Fragment key={i}>
            <div className={`character-sheet__field character-sheet__skills--name-${i + 2}`}>
              <p>{ skill.name.toUpperCase() }</p>
            </div>
            <div className={`character-sheet__field character-sheet__skills--roll-${i + 2}`}>
              <p>{ getRawBonusFromAbility(skill, characterState) }</p>
            </div>
            <div className={`character-sheet__field character-sheet__skills--bonus-${i + 2}`}>
              <p>{ skill.bonus }</p>
            </div>
            <div className={`character-sheet__field character-sheet__skills--total-${i + 2}`}>
              <p>+ { getBonusFromAbility(skill, characterState) }</p>
            </div>
            {/* <div className={`character-sheet__field character-sheet__skills--type-${i + 2}`}>
              <p>{ skill.type.toUpperCase() }</p>
            </div> */}
          </React.Fragment>
        );
      })}
    </div>
  );
}
