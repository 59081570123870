import api from '../config/axios';


// GET Routes

export const getAllLanguages = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/language', auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editLanguage = async (language: Language) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/language', language, auth);
  } catch (error) {
    console.log(error);
  }
};

// POST Routes

export const addLanguage = async (language: Language) => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/language', language, auth);
  } catch (error) {
    console.log(error);
  }
};

// DELETE Routes

export const deleteLanguage = async (id: number) => {
  try {
    const auth = { withCredentials: true };
    await api.delete(`/api/language/${id}`, auth);
  } catch (error) {
    console.log(error);
  }
};
