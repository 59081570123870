import React from "react";
import { useAppSelector } from "../redux/hooks";
import { fetchSmallFeaturedContentState } from "../redux/reducers/featuredContentSlice";


export default function HomeBottomGrid() {
  const { featureBoxImg1, featureBoxImg2, featureBoxImg3, featureBoxImg4 }: SmallFeaturedContent = useAppSelector(fetchSmallFeaturedContentState) || {featureBoxImg1: '', featureBoxImg2: '', featureBoxImg3: '', featureBoxImg4: ''};


  return (
    <div className="home-bottom-grid">
      <div className="home-bottom-grid-content">
        <div className="home-bottom-grid-content__box">
          <img
            className="header__featured-content-box"
            src={featureBoxImg1 ? featureBoxImg1 : '/images/boxes/small_feature_box.png'}
            alt="Small feature box image"
            draggable={false}
          />
        </div>
        <div className="home-bottom-grid-content__box">
          <img
            className="header__featured-content-box"
            src={featureBoxImg2 ? featureBoxImg2 : '/images/boxes/small_feature_box.png'}
            alt="Small feature box image"
            draggable={false}
          />
        </div>
        <div className="home-bottom-grid-content__box">
          <img
            className="header__featured-content-box"
            src={featureBoxImg3 ? featureBoxImg3 : '/images/boxes/small_feature_box.png'}
            alt="Small feature box image"
            draggable={false}
          />
        </div>
        <div className="home-bottom-grid-content__box">
          <img
            className="header__featured-content-box"
            src={featureBoxImg4 ? featureBoxImg4 : '/images/boxes/small_feature_box.png'}
            alt="Small feature box image"
            draggable={false}
          />
        </div>
      </div>
    </div>
  );
}
