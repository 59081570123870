import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { fetchCultureTraitsData } from "../../redux/reducers/cultureTraitsSlice";
import { editCultureTrait } from "../../scripts/controllers/cultureTraitsController";


export default function AdminCultureTraits() {
  const cultureTraitsState = useAppSelector(fetchCultureTraitsData);
  const [traitEdited, setTraitEdited] = useState<CultureTrait>();
  const [selectedCulture, setSelectedCulture] = useState('');

  const saveCultureTraitChanges = async (e: FormEvent) => {
    e.preventDefault();
    // if (traitEdited.id === -1) {
    //   const updatedBlessing = {...traitEdited, id: `${traitEdited.trait.toLowerCase()}-${getRandomInt(0, 9999)}`};
    //   await addBlessing(updatedBlessing);
    // } else {
    //   await editBlessing(traitEdited);
    // }
    await editCultureTrait(traitEdited);
    window.location.href = '/admin?s=cultures';
  };
  
  const deleteCultureTrait = async (id: number) => {
    if (confirm('Are you sure?')) {
      // await deleteBlessing(index);
      window.location.href = '/admin?s=cultures';
    }
  };


  return (
    <div className="admin-page__culture-traits">
      {!traitEdited ?
        <>
          <div className="admin-page__culture-traits-form--top-buttons">
            {/* <button onClick={() => setTraitEdited({ id: -1, trait: '', culture: '', type: 'positive' })}>Add</button> */}
          </div>
          {!selectedCulture &&
            <div className="admin-page__culture-traits--culture-list">
              <button onClick={() => setSelectedCulture('apsu')}>Apsu</button>
              <button onClick={() => setSelectedCulture('atar')}>Atar</button>
              <button onClick={() => setSelectedCulture('brahmi')}>Brahmi</button>
              <button onClick={() => setSelectedCulture('gavlo')}>Gavlo</button>
              <button onClick={() => setSelectedCulture('edennian')}>Edennian</button>
              <button onClick={() => setSelectedCulture('mynocean')}>Mynocean</button>
              <button onClick={() => setSelectedCulture('nifilian')}>Nifilian</button>
              <button onClick={() => setSelectedCulture('sasson')}>Sasson</button>
              <button onClick={() => setSelectedCulture('sandlo')}>Sandlo</button>
              <button onClick={() => setSelectedCulture('tepex')}>Tepex</button>
              <button onClick={() => setSelectedCulture('xi-an')}>XI&apos;AN</button>
            </div>
          }
          {selectedCulture && <button onClick={() => setSelectedCulture('')}>Select Different Culture</button>}
          {selectedCulture && cultureTraitsState.map((cultureTrait: CultureTrait) => {
            if (cultureTrait.culture !== selectedCulture) return null;

            return (
              <div className="admin-page__culture-traits-box" key={cultureTrait.id}>
                <div className="admin-page__culture-traits-box--title">
                  <div className="admin-page__culture-traits-box--container">
                    <p className={`admin-page__culture-traits-box--container--text${cultureTrait.type === 'negative' ? '--red' : ''}`}>{cultureTrait.type}</p>
                    <h3>{cultureTrait.trait}</h3>
                  </div>
                  <button onClick={() => setTraitEdited(cultureTrait)}>Edit</button>
                  <button onClick={() => deleteCultureTrait(cultureTrait.id)}>Delete</button>
                </div>
              </div>
            );
          })}
        </>
        :
        <form className="admin-page__culture-traits-form" onSubmit={(e) => saveCultureTraitChanges(e)}>
          <div className="admin-page__culture-traits-form--top-buttons">
            <button type="button" onClick={() => setTraitEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__culture-traits-form--inputs">
            <label>
              Culture
              <select
                value={traitEdited.culture}
                onChange={(e) => setTraitEdited({...traitEdited, culture: e.target.value})}
                required
              >
                <option value={'apsu'}>Apsu</option>
                <option value={'atar'}>Atar</option>
                <option value={'brahmi'}>Brahmi</option>
                <option value={'gavlo'}>Gavlo</option>
                <option value={'edennian'}>Edennian</option>
                <option value={'mynocean'}>Mynocean</option>
                <option value={'nifilian'}>Nifilian</option>
                <option value={'sasson'}>Sasson</option>
                <option value={'sandlo'}>Sandlo</option>
                <option value={'tepex'}>Tepex</option>
                <option value={'xi-an'}>XI&apos;AN</option>
              </select>
            </label>
            <label>
              Type
              <select
                value={traitEdited.type}
                onChange={(e) => setTraitEdited({...traitEdited, type: (e.target.value as any)})}
                required
              >
                <option value={'positive'}>Positive</option>
                <option value={'negative'}>Negative</option>
              </select>
            </label>
            <label>
              Trait
              <textarea
                value={traitEdited.trait}
                onChange={(e) => setTraitEdited({...traitEdited, trait: e.target.value})}
                required
              />
            </label>
          </div>
        </form>
      }
    </div>
  );
}
