import { rollDice } from "./diceRolls";


interface GearState {
  equipment: Gear[]
  armor: Gear[]
  tools: Gear[]
  instruments: Gear[]
  rations: Gear[]
}

export const characterStateHasIndex = (array: any[], index: string): boolean => {
  let hasIndex = false;
  array.forEach((arrayItem: any) => {
    if (arrayItem.index === index) hasIndex = true;
  });
  return hasIndex;
};

export const determineClassProf = (className: string): string => {
  switch (className) {
  case 'warrior':
    return 'physical';
  case 'spy':
    return 'awareness';
  case 'musician':
    return 'social';
  case 'hunter':
    return 'awareness';
  case 'herbalist':
    return 'education';
  case 'explorer':
    return 'education';
  case 'brawler':
    return 'awareness';
  case 'blacksmith':
    return 'physical';
  case 'beastmaster':
    return 'education';
  default:
    return '';
  }
};

export const determineClassStartingSilver = (className: string): number => {
  switch (className) {
  case 'warrior':
    return rollDice(3, 4).total * 10;
  case 'spy':
    return rollDice(4, 4).total * 10;
  case 'musician':
    return rollDice(4, 4).total * 10;
  case 'hunter':
    return rollDice(3, 4).total * 10;
  case 'herbalist':
    return rollDice(4, 4).total * 10;
  case 'explorer':
    return rollDice(3, 4).total * 10;
  case 'brawler':
    return rollDice(3, 4).total * 10;
  case 'blacksmith':
    return rollDice(5, 4).total * 10;
  case 'beastmaster':
    return rollDice(4, 4).total * 10;
  default:
    return 0;
  }
};

export const determineClassStartingRestDice = (className: string): Dice => {
  switch (className) {
  case 'warrior':
    return { amount: 3, type: 4, display: '3d4' };
  case 'spy':
    return { amount: 2, type: 4, display: '2d4' };
  case 'musician':
    return { amount: 2, type: 4, display: '2d4' };
  case 'hunter':
    return { amount: 2, type: 4, display: '2d4' };
  case 'herbalist':
    return { amount: 2, type: 4, display: '2d4' };
  case 'explorer':
    return { amount: 2, type: 4, display: '2d4' };
  case 'brawler':
    return { amount: 2, type: 4, display: '2d4' };
  case 'blacksmith':
    return { amount: 3, type: 4, display: '3d4' };
  case 'beastmaster':
    return { amount: 2, type: 4, display: '2d4' };
  default:
    return { amount: 3, type: 4, display: '3d4' };
  }
};

interface Score {
  value: number
  selectedAbility: string
}
export const determineClassPreferredStat = (className: string): Score => {
  switch (className) {
  case 'warrior':
    return { selectedAbility: 'phy', value: 0 };
  case 'spy':
    return { selectedAbility: 'awar', value: 0 };
  case 'musician':
    return { selectedAbility: 'soc', value: 0 };
  case 'hunter':
    return { selectedAbility: 'awar', value: 0 };
  case 'herbalist':
    return { selectedAbility: 'edu', value: 0 };
  case 'explorer':
    return { selectedAbility: 'edu', value: 0 };
  case 'brawler':
    return { selectedAbility: 'awar', value: 0 };
  case 'blacksmith':
    return { selectedAbility: 'phy', value: 0 };
  case 'beastmaster':
    return { selectedAbility: 'edu', value: 0 };
  default:
    return { selectedAbility: 'phy', value: 0 };
  }
};

export const determinePlayerDailySlotsAvailable = (level: number): number => {
  if (level <= 2) {
    return 1;
  } else if (level >= 3 && level < 5) {
    return 2;
  } else if (level >= 5  && level < 7) {
    return 3;
  } else if (level >= 7  && level < 9) {
    return 4;
  } else {
    return 5;
  }
};

// Returns the value of the type of skill passed in
export const getBonusFromAbility = (skill: Skill, characterState: Character): number => {
  switch (skill.type) {
  case 'phy':
    return characterState.physical.value + skill.bonus;
  case 'ed':
    return characterState.education.value + skill.bonus;
  case 'tech':
    return characterState.technical.value + skill.bonus;
  case 'soc':
    return characterState.social.value + skill.bonus;
  case 'awar':
    return characterState.awareness.value + skill.bonus;
  case 'cour':
    return characterState.courage.value + skill.bonus;
  default:
    return 0;
  }
};

// Returns the value of the type of skill passed in, without bonus
export const getRawBonusFromAbility = (skill: Skill, characterState: Character): number => {
  switch (skill.type) {
  case 'phy':
    return characterState.physical.value;
  case 'ed':
    return characterState.education.value;
  case 'tech':
    return characterState.technical.value;
  case 'soc':
    return characterState.social.value;
  case 'awar':
    return characterState.awareness.value;
  case 'cour':
    return characterState.courage.value;
  default:
    return 0;
  }
};

export const getStartingGearFromClass = (className: string, gearState: GearState, weaponsState: Weapon[]): StartingGearOptions => {
  const { equipment, armor, tools, instruments, rations } = gearState;
  switch (className) {
  case 'warrior':
    return {
      row1: [
        { name: 'Polearm', subOptions: weaponsState.filter((weapon: Weapon) => {
          return weapon.properties.includes('polearms');
        })},
        { name: '1 or 2 handed sword', subOptions: weaponsState.filter((weapon: Weapon) => {
          return weapon.properties.includes('one-handed') || weapon.properties.includes('two-handed');
        })},
      ],
      row2: [
        { name: 'Longsword' },
        { name: 'Shield' },
      ]
    };
  case 'spy':
    return {
      row1: [
        { name: 'Throwing Knives' },
        { name: 'Dagger' },
      ],
      row2: [
        { name: 'Shortsword' },
        { name: 'Crossbow' },
      ]
    };
  case 'musician':
    return {
      row1: [
        { name: 'Dagger' },
        { name: '' },
      ],
      row2: [
        { name: 'Shortsword' },
        { name: 'Shortbow' },
      ],
      row3: [
        { name: 'Musical Instrument of Choice', subOptions: instruments },
      ],
    };
  case 'hunter':
    return {
      row1: [
        { name: 'Shortbow' },
        { name: 'Sling' },
      ],
      row2: [
        { name: 'Dagger' },
        { name: 'Shortsword' },
      ]
    };
  case 'herbalist':
    return {
      row1: [
        { name: 'Dagger' },
        { name: 'Shortsword' },
      ],
      row2: [
        { name: 'Crossbow' },
        { name: 'Sling' },
      ]
    };
  case 'explorer':
    return {
      row1: [
        { name: 'Shortsword' },
        { name: 'Dagger' },
      ],
      row2: [
        { name: 'Crossbow' },
        { name: 'Bow' },
      ]
    };
  case 'brawler':
    return {
      row1: [
        { name: 'Throwing Knives' },
        { name: 'Dagger' },
      ],
      row2: [
        { name: 'Meteor Hammer' },
        { name: 'Brass Knuckles' },
      ]
    };
  case 'blacksmith':
    return {
      row1: [
        { name: 'Hammer' },
        { name: 'Hatchet' },
      ],
      row2: [
        { name: 'Longsword' },
        { name: 'War Hammer' },
      ]
    };
  case 'beastmaster':
    return {
      row1: [
        { name: 'Bolas' },
        { name: 'Hatchet' },
      ],
      row2: [
        { name: 'Shortsword' },
        { name: 'Bow' },
      ]
    };
  default:
    return;
  }
};

// Focus ability is the extra skill bonus based on class
export const determineClassFocusAbilityBonuses = (className: string): Skill[] => {
  switch (className) {
  case 'warrior':
    return [
      { name: 'athletics', type: 'phy', bonus: 2 },
      { name: 'grapple', type: 'phy', bonus: 1 }
    ];
  case 'spy':
    return [
      { name: 'subtle', type: 'awar', bonus: 2 },
      { name: 'convince', type: 'soc', bonus: 1 }
    ];
  case 'musician':
    return [
      { name: 'perform', type: 'soc', bonus: 2 },
      { name: 'intuition', type: 'soc', bonus: 1 }
    ];
  case 'hunter':
    return [
      { name: 'tracking', type: 'awar', bonus: 2 },
      { name: 'perception', type: 'awar', bonus: 1 }
    ];
  case 'herbalist':
    return [
      { name: 'nature', type: 'ed', bonus: 2 },
      { name: 'knowledge', type: 'ed', bonus: 1 }
    ];
  case 'explorer':
    return [
      { name: 'decipher', type: 'ed', bonus: 2 },
      { name: 'knowledge', type: 'ed', bonus: 1 }
    ];
  case 'brawler':
    return [
      { name: 'grapple', type: 'phy', bonus: 2 },
      { name: 'reflex', type: 'tech', bonus: 1 }
    ];
  case 'blacksmith':
    return [
      { name: 'athletics', type: 'phy', bonus: 2 },
      { name: 'grapple', type: 'phy', bonus: 1 }
    ];
  case 'beastmaster':
    return [
      { name: 'nature', type: 'ed', bonus: 2 },
      { name: 'knowledge', type: 'ed', bonus: 1 }
    ];
  default:
    return [];
  }
};

export const getMaxCarryWeight = (phy: number): number => {
  const phyBonus = 25 * phy;
  return 100 + phyBonus;
};

export const filterWeaponsByProperty = (weaponsArray: Weapon[], properties: string): Weapon[] => {
  return weaponsArray.filter((weapon: Weapon) => {
    return weapon.properties.includes(properties);
  });
};

export const getClassWeaponsUsed = (className: string) => {
  switch (className) {
  case 'warrior':
    return {
      light: true,
      oneHanded: true,
      twoHanded: true,
      polearms: true,
      ranged: true,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: true
    };
  case 'spy':
    return {
      light: true,
      oneHanded: true,
      twoHanded: false,
      polearms: false,
      ranged: true,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: true
    };
  case 'musician':
    return {
      light: true,
      oneHanded: true,
      twoHanded: false,
      polearms: false,
      ranged: true,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: false
    };
  case 'hunter':
    return {
      light: true,
      oneHanded: true,
      twoHanded: false,
      polearms: false,
      ranged: true,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: true
    };
  case 'herbalist':
    return {
      light: true,
      oneHanded: true,
      twoHanded: false,
      polearms: false,
      ranged: true,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: false
    };
  case 'explorer':
    return {
      light: true,
      oneHanded: true,
      twoHanded: false,
      polearms: false,
      ranged: true,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: false
    };
  case 'brawler':
    return {
      light: true,
      oneHanded: true,
      twoHanded: false,
      polearms: false,
      ranged: false,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: true
    };
  case 'blacksmith':
    return {
      light: true,
      oneHanded: true,
      twoHanded: true,
      polearms: false,
      ranged: false,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: false
    };
  case 'beastmaster':
    return {
      light: true,
      oneHanded: true,
      twoHanded: false,
      polearms: true,
      ranged: true,
      specializedAmmo: true,
      grenadesAndExplosives: true,
      concoctionsAndElixers: true,
      unique: false
    };
  default:
    return;
  }
};

export const getClassArmorUsed = (className: string) => {
  switch (className) {
  case 'warrior':
    return {
      light: true,
      medium: true,
      heavy: true,
      unarmored: false
    };
  case 'spy':
    return {
      light: true,
      medium: false,
      heavy: false,
      unarmored: false
    };
  case 'musician':
    return {
      light: true,
      medium: false,
      heavy: false,
      unarmored: false
    };
  case 'hunter':
    return {
      light: true,
      medium: true,
      heavy: false,
      unarmored: false
    };
  case 'herbalist':
    return {
      light: true,
      medium: false,
      heavy: false,
      unarmored: false
    };
  case 'explorer':
    return {
      light: true,
      medium: false,
      heavy: false,
      unarmored: false
    };
  case 'brawler':
    return {
      light: false,
      medium: false,
      heavy: false,
      unarmored: true
    };
  case 'blacksmith':
    return {
      light: false,
      medium: false,
      heavy: false,
      unarmored: true
    };
  case 'beastmaster':
    return {
      light: true,
      medium: false,
      heavy: false,
      unarmored: false
    };
  default:
    return;
  }
};
