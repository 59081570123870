import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import characterSlice from './reducers/characterSlice';
import gearSlice from './reducers/gearSlice';
import blessingsSlice from './reducers/blessingsSlice';
import weaponsSlice from './reducers/weaponsSlice';
import perksSlice from './reducers/perksSlice';
import featuresSlice from './reducers/featuresSlice';
import languagesSlice from './reducers/languagesSlice';
import featuredContentSlice from './reducers/featuredContentSlice';
import conditionsSlice from './reducers/conditionsSlice';
import gearKitsSlice from './reducers/gearKitsSlice';
import specialAmmoSlice from './reducers/specialAmmoSlice';
import cultureTraitsSlice from './reducers/cultureTraitsSlice';
import userSlice from './reducers/userSlice';
import productsSlice from './reducers/productsSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    character: characterSlice,
    gear: gearSlice,
    blessings: blessingsSlice,
    weapons: weaponsSlice,
    perks: perksSlice,
    features: featuresSlice,
    languages: languagesSlice,
    cultureTraits: cultureTraitsSlice,
    featuredContent: featuredContentSlice,
    conditions: conditionsSlice,
    gearKits: gearKitsSlice,
    products: productsSlice,
    specializedAmmo: specialAmmoSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
