import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { getRandomInt } from "../../scripts/tools/utils";
import { fetchWeaponsState } from "../../redux/reducers/weaponsSlice";
import { addWeapon, addWeaponCondition, deleteWeapon, deleteWeaponCondition, editWeapon, getAllWeapons, getWeapon } from "../../scripts/controllers/weaponsController";
import { fetchConditionsState } from "../../redux/reducers/conditionsSlice";


export default function AdminWeapons() {
  const weaponsState: Weapon[] = useAppSelector(fetchWeaponsState);
  const conditionsState: Condition[] = useAppSelector(fetchConditionsState);
  const [weaponEdited, setWeaponEdited] = useState<Weapon>();
  const [amount, setAmount] = useState(0);
  const [diceType, setDiceType] = useState(4);
  const [mod, setMod] = useState(0);
  

  const saveWeaponChanges = async (e: FormEvent) => {
    e.preventDefault();
    const oldConditionsData: Condition[] = weaponEdited.conditionsInflicted !== undefined && weaponEdited.id !== -1 ? (await getWeapon(weaponEdited.id)).conditionsInflicted : [];
    
    if (weaponEdited.index === 'new-weapon') {
      const updatedWeapon = {...weaponEdited, index: `${weaponEdited.name.toLowerCase()}-${getRandomInt(0, 9999)}`, exhaustionInflicted: JSON.stringify(weaponEdited.exhaustionInflicted) as any};
      await addWeapon(updatedWeapon);
    } else {
      await editWeapon({ ...weaponEdited, exhaustionInflicted: JSON.stringify(weaponEdited.exhaustionInflicted) as any});
    }

    if (weaponEdited.conditionsInflicted) {
      const newConditions = weaponEdited.conditionsInflicted.filter((condition: Condition) => {
        return !oldConditionsData.some((oldCondition: Condition) => oldCondition.id === condition.id);
      });
      const conditionsToDelete = oldConditionsData.filter((oldCondition: Condition) => {
        return !weaponEdited.conditionsInflicted.some((condition: Condition) => condition.id === oldCondition.id);
      });

      const allWeapons = (await getAllWeapons());
      const weaponId = weaponEdited.id !== -1 ? weaponEdited.id : allWeapons[allWeapons.length - 1].id;
      newConditions.forEach(async (condition: Condition) => {
        await addWeaponCondition(weaponId, condition);
      });
      conditionsToDelete.forEach(async (condition: Condition) => {
        await deleteWeaponCondition(weaponEdited.id, condition.id);
      });
    }
    
    window.location.href = '/admin?s=weapons';
  };

  const handleDeleteWeapon = async (id: number) => {
    if (confirm('Are you sure?')) {
      await deleteWeapon(id);
      window.location.href = '/admin?s=weapons';
    }
  };

  const addDamage = () => {
    if (amount === 0) {
      setWeaponEdited({ ...weaponEdited, exhaustionInflicted: [...weaponEdited.exhaustionInflicted, { types: [], dice: { amount: amount, type: diceType, mod: mod, display: `${mod}` }}]});
    } else {
      setWeaponEdited({ ...weaponEdited, exhaustionInflicted: [...weaponEdited.exhaustionInflicted, { types: [], dice: { amount: amount, type: diceType, mod: mod, display: `${amount}d${diceType}${mod > 0 ? `+${mod}` : ''}` }}]});
    }
  };

  const addDamageType = (index: number, type: string) => {
    const updatedDamageTypes = [...weaponEdited.exhaustionInflicted[index].types, type];
    const updatedDamages = weaponEdited.exhaustionInflicted.map((damage: Damage, i) => {
      if (i === index) {
        return { ...damage, types: updatedDamageTypes };
      } else {
        return damage;
      }
    });
    setWeaponEdited({ ...weaponEdited, exhaustionInflicted: updatedDamages });
  };

  const deleteDamage = (damage: Damage) => {
    const updatedDamage: Damage[] = weaponEdited.exhaustionInflicted.filter((_damage: Damage) => {
      return damage !== _damage;
    });
    setWeaponEdited({ ...weaponEdited, exhaustionInflicted: updatedDamage });
  };

  const deleteProperty = (property: string) => {
    const updatedProperties = weaponEdited.properties.split(', ').filter((_property: string) => {
      return _property !== property;
    }).join(', ');
    setWeaponEdited({ ...weaponEdited, properties: updatedProperties });
  };

  const addProperty = (property: string) => {
    const newProperties = `${weaponEdited.properties}, ${property}`.split(', ').filter((_property: string) => {
      if (_property) return _property;
    }).join(', ');
    setWeaponEdited({...weaponEdited, properties: newProperties});
  };

  const clearDamageTypes = (index: number) => {
    setWeaponEdited({ ...weaponEdited, exhaustionInflicted: weaponEdited.exhaustionInflicted.map((damage: Damage, i) => {
      if (i === index) {
        return { ...damage, types: [] };
      } else {
        return damage;
      }
    })});
  };

  const deleteConditionData = (condition: Condition) => {
    const updatedConditions: Condition[] = weaponEdited.conditionsInflicted.filter((_condition: Condition) => {
      return condition !== _condition;
    });
    setWeaponEdited({ ...weaponEdited, conditionsInflicted: updatedConditions });
  };

  const addConditionData = (condition: Condition) => {
    if (weaponEdited.conditionsInflicted === undefined) {
      setWeaponEdited({ ...weaponEdited, conditionsInflicted: [] });
      weaponEdited.conditionsInflicted = [];
    }
    setWeaponEdited({ ...weaponEdited, conditionsInflicted: [...weaponEdited.conditionsInflicted, condition] });
  };


  return (
    <div className="admin-page__weapons">
      {!weaponEdited ?
        <>
          <div className="admin-page__weapons-form--top-buttons">
            <button onClick={() => setWeaponEdited({ id: -1, weaponId: 0, index: 'new-weapon', name: '', desc: '', range: { min: 0, max: 0 }, properties: '', exhaustionInflicted: [{"dice":{"amount":1,"type":4,"mod":0,"display":"1d4"},"types":["bludgeoning"]}], lbs: 0, cost: {amount:0,currency:'gp'}, qty: 0, carried: false, canHaveSpecialAmmo: false })}>Add</button>
          </div>
          {weaponsState.map((weapon: Weapon) => {
            return (
              <React.Fragment key={weapon.index}>
                <div className="admin-page__weapons-box">
                  <div className="admin-page__weapons-box--title">
                    <h3>{ weapon.name }</h3>
                    <button onClick={() => setWeaponEdited(weapon)}>Edit</button>
                    <button onClick={() => handleDeleteWeapon(weapon.id)}>Delete</button>
                  </div>
                  {weapon.range.min > 0 ?
                    <p>Range: { weapon.range.min }{ weapon.range.max > 0 && `/${weapon.range.max}` } ft</p>
                    :
                    <p>Range: melee</p>
                  }
                  <p>Properties: { weapon.properties }</p>
                  <p>{ weapon.lbs } lbs, { weapon.cost.amount } { weapon.cost.currency }</p>
                </div>
              </React.Fragment>
            );
          })}
        </>
        :
        <form className="admin-page__weapons-form" onSubmit={(e) => saveWeaponChanges(e)}>
          <div className="admin-page__weapons-form--top-buttons">
            <button type="button" onClick={() => setWeaponEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__weapons-form--inputs">
            <label>
              Name
              <input
                value={weaponEdited.name}
                onChange={(e) => setWeaponEdited({...weaponEdited, name: e.target.value})}
                required
              />
            </label>
            <label>
              Description
              <textarea
                value={weaponEdited.desc || ''}
                onChange={(e) => setWeaponEdited({...weaponEdited, desc: e.target.value})}
              />
            </label>
            <label>
              Range Min
              <input
                value={weaponEdited.range.min}
                onChange={(e) => setWeaponEdited({...weaponEdited, range: { ...weaponEdited.range, min: Number(e.target.value) }})}
                type="number"
                required
              />
            </label>
            <label>
              Range Max
              <input
                value={weaponEdited.range.max}
                onChange={(e) => setWeaponEdited({...weaponEdited, range: { ...weaponEdited.range, max: Number(e.target.value) }})}
                type="number"
              />
            </label>
            <label>
              Lbs
              <input
                value={weaponEdited.lbs}
                onChange={(e) => setWeaponEdited({...weaponEdited, lbs: Number(e.target.value)})}
                type="number"
                required
              />
            </label>
            <label>
              Can Have Specialized Ammo Attached
              <input
                type="checkbox"
                checked={weaponEdited.canHaveSpecialAmmo}
                onChange={(e) => setWeaponEdited({...weaponEdited, canHaveSpecialAmmo: e.target.checked})}
              />
            </label>

            <h2 style={{ 'marginBottom':'0' }}>Properties</h2>
            {weaponEdited.properties && weaponEdited.properties.split(', ').map((property: string, i) => {
              return (
                <div key={i} style={{ 'display':'flex','gap':'0.5rem' }}>
                  <p>{ property }</p>
                  <button type="button" onClick={() => deleteProperty(property)}>Delete</button>
                </div>
              );
            })}
            <div className="admin-page__weapons--properties-inputs">
              <label>
                Properties
                <select
                  value={weaponEdited.properties}
                  onChange={(e) => addProperty(e.target.value)}
                >
                  <option value="">-- Pick Option --</option>
                  <option value="light">light</option>
                  <option value="one-handed">one-handed</option>
                  <option value="two-handed">two-handed</option>
                  <option value="polearms">polearms</option>
                  <option value="ranged">ranged</option>
                  <option value="specialized-ammo">specialized-ammo</option>
                  <option value="grenades/explosives">grenades/explosives</option>
                  <option value="concoctions/elixers">concoctions/elixers</option>
                  <option value="unique">unique</option>
                </select>
              </label>
            </div>

            <h2 style={{ 'marginBottom':'0' }}>Damage</h2>
            {weaponEdited.exhaustionInflicted.map((damage: Damage, i) => {
              return (
                <React.Fragment key={i}>
                  <div style={{ 'display':'flex','gap':'0.5rem' }}>
                    <p>{ damage.dice.display } ({ damage.types.join(', ') })</p>
                    <button type="button" onClick={() =>deleteDamage(damage)}>Delete</button>
                  </div>
                  <label className="admin-page__weapons--damage-types">
                    Damage Type
                    <select
                      onChange={(e) => addDamageType(i, e.target.value)}
                      value={''}
                    >
                      <option value="">-- Pick Option --</option>
                      <option value="slashing">slashing</option>
                      <option value="piercing">piercing</option>
                      <option value="bludgeoning">bludgeoning</option>
                      <option value="fire">fire</option>
                      <option value="cold">cold</option>
                      <option value="necrotic">necrotic</option>
                      <option value="radiant">radiant</option>
                      <option value="lightning">lightning</option>
                      <option value="thunder">thunder</option>
                      <option value="force">force</option>
                      <option value="psychic">psychic</option>
                      <option value="poison">poison</option>
                      <option value="acid">acid</option>
                    </select>
                    <button type="button" onClick={() => clearDamageTypes(i)}>Clear Damage Types</button>
                  </label>
                </React.Fragment>
              );
            })}
            <div className="admin-page__weapons--damage-inputs">
              <label>
                Amount
                <input
                  onChange={(e) => setAmount(Number(e.target.value))}
                  type="number"
                />
              </label>
              <label>
                Dice
                <select
                  onChange={(e) => setDiceType(Number(e.target.value))}
                  required
                >
                  <option value="4">d4</option>
                  <option value="6">d6</option>
                  <option value="8">d8</option>
                  <option value="10">d10</option>
                  <option value="12">d12</option>
                </select>
              </label>
              <label>
                Modifier
                <input
                  onChange={(e) => setMod(Number(e.target.value))}
                  type="number"
                />
              </label>
              <button type="button" onClick={addDamage}>Add</button>
            </div>

            <h2 style={{ 'marginBottom':'0' }}>Conditions Inflicted</h2>
            {weaponEdited.conditionsInflicted && weaponEdited.conditionsInflicted.map((condition: Condition) => {
              return (
                <div key={condition.name} style={{ 'display':'flex','gap':'0.5rem' }}>
                  <p>{ condition.name }</p>
                  <button type="button" onClick={() => deleteConditionData(condition)}>Delete</button>
                </div>
              );
            })}
            <div className="admin-page__weapons--properties-inputs">
              <label>
                Conditions
                <select
                  onChange={(e) => addConditionData(conditionsState.find((condition: Condition) => {
                    return condition.name === e.target.value;
                  }))}
                  value={''}
                >
                  <option value="">-- Pick Option --</option>
                  {conditionsState.map((condition: Condition) => {
                    return (
                      <option key={condition.name} value={condition.name}>{ condition.name }</option>
                    );
                  })}
                </select>
              </label>
            </div>

            <h2 style={{ 'marginBottom':'0' }}>Cost</h2>
            <label>
              Amount
              <input
                value={weaponEdited.cost.amount}
                onChange={(e) => setWeaponEdited({...weaponEdited, cost: { ...weaponEdited.cost, amount: Number(e.target.value)}})}
                type="number"
                required
              />
            </label>
            <label>
              Currency
              <select
                value={weaponEdited.cost.currency}
                onChange={(e) => setWeaponEdited({...weaponEdited, cost: { ...weaponEdited.cost, currency: e.target.value}})}
                required
              >
                <option value="gp">GP</option>
                <option value="sp">SP</option>
                <option value="cp">CP</option>
              </select>
            </label>
          </div>
        </form>
      }
    </div>
  );
}
