import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { fetchBlessingsState } from "../../redux/reducers/blessingsSlice";
import { addBlessing, deleteBlessing, editBlessing } from "../../scripts/controllers/blessingsController";
import { getRandomInt } from "../../scripts/tools/utils";


export default function AdminBlessings() {
  const blessingsState: Blessing[] = useAppSelector(fetchBlessingsState);
  const [blessingEdited, setBlessingEdited] = useState<Blessing>();

  const saveBlessingChanges = async (e: FormEvent) => {
    e.preventDefault();
    if (blessingEdited.index === 'new-blessing') {
      const updatedBlessing = {...blessingEdited, index: `${blessingEdited.name.toLowerCase()}-${getRandomInt(0, 9999)}`};
      await addBlessing(updatedBlessing);
    } else {
      await editBlessing(blessingEdited);
    }
    window.location.href = '/admin?s=blessings';
  };

  const handleDeleteBlessing = async (index: string) => {
    if (confirm('Are you sure?')) {
      await deleteBlessing(index);
      window.location.href = '/admin?s=blessings';
    }
  };


  return (
    <div className="admin-page__blessings">
      {!blessingEdited ?
        <>
          <div className="admin-page__blessings-form--top-buttons">
            <button onClick={() => setBlessingEdited({ index: 'new-blessing', name: '', desc: '' })}>Add</button>
          </div>
          {blessingsState.map((blessing: Blessing) => {
            return (
              <React.Fragment key={blessing.index}>
                <div className="admin-page__blessings-box">
                  <div className="admin-page__blessings-box--title">
                    <h3>{blessing.name}</h3>
                    <button onClick={() => setBlessingEdited(blessing)}>Edit</button>
                    <button onClick={() => handleDeleteBlessing(blessing.index)}>Delete</button>
                  </div>
                  <p>{blessing.desc}</p>
                </div>
              </React.Fragment>
            );
          })}
        </>
        :
        <form className="admin-page__blessings-form" onSubmit={(e) => saveBlessingChanges(e)}>
          <div className="admin-page__blessings-form--top-buttons">
            <button type="button" onClick={() => setBlessingEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__blessings-form--inputs">
            <label>
              Title
              <input
                value={blessingEdited.name}
                onChange={(e) => setBlessingEdited({...blessingEdited, name: e.target.value})}
                required
              />
            </label>
            <label>
              Description
              <textarea
                value={blessingEdited.desc}
                onChange={(e) => setBlessingEdited({...blessingEdited, desc: e.target.value})}
                required
              />
            </label>
          </div>
        </form>
      }
    </div>
  );
}
