import api from "../config/axios";


// GET Routes

export const getAllWeapons = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/weapon', auth);
    res.data.forEach((weapon: any) => {
      weapon.cost = JSON.parse(weapon.cost);
      weapon.range = JSON.parse(weapon.range);
      weapon.exhaustionInflicted = JSON.parse(weapon.exhaustionInflicted);      
      if (!weapon.conditionsInflicted[0]) weapon.conditionsInflicted = [];
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getWeapon = async (id: number) => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get(`/api/weapon/${id}`, auth);
    res.data.cost = JSON.parse(res.data.cost);
    res.data.range = JSON.parse(res.data.range);
    res.data.exhaustionInflicted = JSON.parse(res.data.exhaustionInflicted);
    if (!res.data.conditionsInflicted || !res.data.conditionsInflicted[0]) res.data.conditionsInflicted = [];
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editWeapon = async (weapon: Weapon) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/weapon', weapon, auth);
  } catch (error) {
    console.log(error);
  }
};

// POST Routes

export const addWeapon = async (weapon: Weapon) => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/weapon', weapon, auth);
  } catch (error) {
    console.log(error);
  }
};

export const addWeaponCondition = async (weaponId: number, condition: Condition) => {
  try {
    const auth = { withCredentials: true };
    const data = { weaponId: weaponId, conditionId: condition.id };
    await api.post('/api/weapon/condition', data, auth);
  } catch (error) {
    console.log(error);
  }
};

// DELETE Routes

export const deleteWeapon = async (id: number) => {
  try {
    const auth = { withCredentials: true };
    await api.delete(`/api/weapon/${id}`, auth);
  } catch (error) {
    console.log(error);
  }
};

export const deleteWeaponCondition = async (weaponId: number, conditionId: number) => {
  try {
    const auth = { withCredentials: true };
    await api.delete(`/api/weapon/condition/${weaponId}, ${conditionId}`, auth);
  } catch (error) {
    console.log(error);
  }
};
