import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface ProductsState {
  products: Product[]
}

const initialState: ProductsState = {
  products: [],
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsData: (state, action) => { state.products = action.payload; },
  }
});

export const {
  setProductsData,
} = productsSlice.actions;

export const fetchProductsState = (state: RootState): Product[] => state.products.products;

export default productsSlice.reducer;
