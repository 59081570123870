import api from '../config/axios';


// GET Routes

export const getFeaturedContent = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/featured-content', auth);
    res.data.featureBoxImgArray = JSON.parse(res.data.featureBoxImgArray);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editFeaturedContent = async (featuredContent: FeaturedContent) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/featured-content', featuredContent, auth);
  } catch (error) {
    console.log(error);
  }
};

export const editSmallFeaturedContent = async (featuredContent: SmallFeaturedContent) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/featured-content/small', featuredContent, auth);
  } catch (error) {
    console.log(error);
  }
};
