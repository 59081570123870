import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterAllies } from "../../../redux/reducers/characterSlice";


export default function SheetAlliesBlock() {
  const dispatch = useAppDispatch();
  const { alliesAndContacts } = useAppSelector(fetchCharacterState);


  return (
    <div className="character-sheet__allies">
      <p className="character-sheet__allies--title">ALLIES AND CONTACTS</p>
      <textarea
        value={alliesAndContacts}
        onChange={(e) => dispatch(setCharacterAllies(e.target.value))}
      />
    </div>
  );
}
