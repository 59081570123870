import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import HomeBottomGrid from '../components/HomeBottomGrid';


export default function HomePage() {
  return (
    <>
      <Layout>
        <Header />
        <HomeBottomGrid />
      </Layout>
    </>
  );
}
