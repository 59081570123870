import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { getRandomInt } from "../../scripts/tools/utils";
import { fetchGearState } from "../../redux/reducers/gearSlice";
import { addArmor, deleteGear, editArmor } from "../../scripts/controllers/gearController";
import { capitalize } from "../../scripts/tools/stringUtils";


interface GearInterface {
  armor: Gear[]
}

export default function AdminArmor() {
  const { armor }: GearInterface = useAppSelector(fetchGearState);
  const [armorEdited, setArmorEdited] = useState<Gear>();

  const saveArmorChanges = async (e: FormEvent) => {
    e.preventDefault();
    if (armorEdited.index === 'new-armor') {
      const updatedArmor = {...armorEdited, index: `${armorEdited.name.toLowerCase()}-${getRandomInt(0, 9999)}`};
      await addArmor(updatedArmor);
    } else {
      await editArmor(armorEdited);
    }
    window.location.href = '/admin?s=armor';
  };

  const handleDeleteArmor = async (id: number) => {
    if (confirm('Are you sure?')) {
      await deleteGear(id);
      window.location.href = '/admin?s=armor';
    }
  };


  return (
    <div className="admin-page__armor">
      {!armorEdited ?
        <>
          <div className="admin-page__armor-form--top-buttons">
            <button onClick={() => setArmorEdited({ id: 0, index: 'new-armor', name: '', desc: '', lbs: 0, cost: {amount:0,currency:'gp'}, defense: 0, qty: 0, type: 'armor', carried: false })}>Add</button>
          </div>
          {armor.map((armor: Gear) => {
            return (
              <React.Fragment key={armor.index}>
                <div className="admin-page__armor-box">
                  <div className="admin-page__armor-box--title">
                    <h3>{ armor.name }</h3>
                    <button onClick={() => setArmorEdited(armor)}>Edit</button>
                    <button onClick={() => handleDeleteArmor(armor.id)}>Delete</button>
                  </div>
                  <p>{ armor.desc }</p>
                  <p>{ capitalize(armor.subtype) }, +{ armor.defense } Defense, { armor.lbs } lbs, { armor.cost.amount } { armor.cost.currency }</p>
                </div>
              </React.Fragment>
            );
          })}
        </>
        :
        <form className="admin-page__armor-form" onSubmit={(e) => saveArmorChanges(e)}>
          <div className="admin-page__armor-form--top-buttons">
            <button type="button" onClick={() => setArmorEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__armor-form--inputs">
            <label>
              Name
              <input
                value={armorEdited.name}
                onChange={(e) => setArmorEdited({...armorEdited, name: e.target.value})}
                required
              />
            </label>
            <label>
              Desc
              <input
                value={armorEdited.desc}
                onChange={(e) => setArmorEdited({...armorEdited, desc: e.target.value})}
              />
            </label>
            <label>
              Defense
              <input
                value={armorEdited.defense}
                onChange={(e) => setArmorEdited({...armorEdited, defense: Number(e.target.value)})}
                type="number"
                required
              />
            </label>
            <label>
              Lbs
              <input
                value={armorEdited.lbs}
                onChange={(e) => setArmorEdited({...armorEdited, lbs: Number(e.target.value)})}
                type="number"
                required
              />
            </label>
            <label>
              Category
              <select
                value={armorEdited.subtype}
                onChange={(e) => setArmorEdited({...armorEdited, subtype: e.target.value })}
                required
              >
                <option value="light">Light</option>
                <option value="medium">Medium</option>
                <option value="heavy">Heavy</option>
                <option value="shield">Shield</option>
              </select>
            </label>
            <h2 style={{ 'marginBottom':'0' }}>Cost</h2>
            <label>
              Amount
              <input
                value={armorEdited.cost.amount}
                onChange={(e) => setArmorEdited({...armorEdited, cost: { ...armorEdited.cost, amount: Number(e.target.value)}})}
                type="number"
                required
              />
            </label>
            <label>
              Currency
              <select
                value={armorEdited.cost.currency}
                onChange={(e) => setArmorEdited({...armorEdited, cost: { ...armorEdited.cost, currency: e.target.value}})}
                required
              >
                <option value="gp">GP</option>
                <option value="sp">SP</option>
                <option value="cp">CP</option>
              </select>
            </label>
          </div>
        </form>
      }
    </div>
  );
}
