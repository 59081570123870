import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { rollDice } from '../../scripts/diceRolls';


interface GearKitsState {
  kits: GearKit[]
}

const initialState: GearKitsState = {
  kits: [
    {
      name: 'Basic Kit',
      content: [
        { name: 'Backpack', qty: 1 },
        { name: 'Bowl', qty: 1 },
        { name: 'Charcoal', qty: 1 },
        { name: 'Flint and striker', qty: 1 },
        { name: 'Rope, hemp (100ft)', qty: 1 },
        { name: 'Torch', qty: 2 },
        { name: 'Utensils', qty: 1 },
        { name: 'Waterskin', qty: 1 },
        { name: 'Smelling salts', qty: 1 },
        { name: 'Fruit', qty: 3 },
        { name: 'Grain (small sack)', qty: 1 }
      ]
    },
    {
      name: 'Explorer Kit',
      content: [
        { name: 'Backpack', qty: 1 },
        { name: 'Blanket', qty: 1 },
        { name: 'Chalk', qty: 1 },
        { name: 'Flint and striker', qty: 1 },
        { name: 'Lantern', qty: 1 },
        { name: 'Grappling hook', qty: 1 },
        { name: 'Rope, hemp (200ft)', qty: 1 },
        { name: 'Ink vial', qty: 1 },
        { name: 'Quill', qty: 1 },
        { name: 'Torch', qty: 2 },
        { name: 'Paper sheet', qty: 2 },
        { name: 'Pitons', qty: 4 },
        { name: 'Sack, medium', qty: 1 },
        { name: 'Waterskin', qty: 1 },
        { name: 'Dried meat (jerky)', qty: 7 }
      ]
    },
    {
      name: 'Delver Kit',
      content: [
        { name: 'Backpack', qty: 1 },
        { name: 'Block and tackle', qty: 1 },
        { name: 'Chalk', qty: 1 },
        { name: 'Flint and striker', qty: 1 },
        { name: 'Grappling hook', qty: 1 },
        { name: 'Rope, hemp (100ft)', qty: 1 },
        { name: 'Ink vial', qty: 1 },
        { name: 'Quill', qty: 1 },
        { name: 'Torch', qty: 2 },
        { name: 'Sack, medium', qty: 1 },
        { name: 'Dried meat (jerky)', qty: 3 }
      ]
    },
    {
      name: 'Technical Kit',
      content: [
        { name: 'Backpack', qty: 1 },
        { name: 'Blanket', qty: 1 },
        { name: 'Candle', qty: 1 },
        { name: 'Flint and striker', qty: 1 },
        { name: 'Ink vial', qty: 2 },
        { name: 'Quill', qty: 1 },
        { name: 'Journal', qty: 1 },
        { name: 'Mortar and pestle', qty: 1 },
        { name: 'Lantern', qty: 1 },
        { name: 'Scales', qty: 1 },
        { name: 'Empty glass vial', qty: 4 },
        { name: 'Material components', qty: rollDice(2, 4).total }
      ]
    },
  ],
};

export const gearKitSlice = createSlice({
  name: 'gearKit',
  initialState,
  reducers: {
    setGearKitsData: (state, action) => { state.kits = action.payload; },
  }
});

export const {
  setGearKitsData,
} = gearKitSlice.actions;

export const fetchGearKitsState = (state: RootState): GearKit[] => state.gearKits.kits;

export default gearKitSlice.reducer;
