import api from "../config/axios";


// GET Routes

export const getAllGearOfType = async (type: string) => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get(`/api/gear/type/${type}`, auth);
    return res.data.map((gear: any) => {
      return { ...gear, cost: JSON.parse(gear.cost) };
    });
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editGear = async (gear: Gear) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/gear', gear, auth);
  } catch (error) {
    console.log(error);
  }
};

export const editArmor = async (gear: Gear) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/gear/armor', gear, auth);
  } catch (error) {
    console.log(error);
  }
};

// POST Routes

export const addGear = async (gear: Gear) => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/gear', gear, auth);
  } catch (error) {
    console.log(error);
  }
};

export const addArmor = async (gear: Gear) => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/gear/armor', gear, auth);
  } catch (error) {
    console.log(error);
  }
};

// DELETE Routes

export const deleteGear = async (id: number) => {
  try {
    const auth = { withCredentials: true };
    await api.delete(`/api/gear/${id}`, auth);
  } catch (error) {
    console.log(error);
  }
};
