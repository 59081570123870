import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { fetchSpecializedAmmoState } from "../../redux/reducers/specialAmmoSlice";
import { addSpecializedAmmo, deleteSpecializedAmmo, editSpecializedAmmo } from "../../scripts/controllers/specialAmmoController";


export default function AdminSpecializedAmmos() {
  const specializedAmmoState: SpecializedAmmo[] = useAppSelector(fetchSpecializedAmmoState);
  const [specializedAmmoEdited, setSpecializedAmmoEdited] = useState<SpecializedAmmo>();

  const saveSpecializedAmmoChanges = async (e: FormEvent) => {
    e.preventDefault();
    if (specializedAmmoEdited.id === -1) {
      await addSpecializedAmmo(specializedAmmoEdited);
    } else {
      await editSpecializedAmmo(specializedAmmoEdited);
    }
    window.location.href = '/admin?s=ammo';
  };

  const handleDeleteSpecializedAmmo = async (id: number) => {
    if (confirm('Are you sure?')) {
      await deleteSpecializedAmmo(id);
      window.location.href = '/admin?s=ammo';
    }
  };
  

  return (
    <div className="admin-page__special-ammo">
      {!specializedAmmoEdited ?
        <>
          <div className="admin-page__special-ammo-form--top-buttons">
            <button onClick={() => setSpecializedAmmoEdited({ id: -1, name: '', desc: '', cost: {amount:0,currency:'GP'}, lbs: 0, aoe: 0, type: 'ammo' })}>Add</button>
          </div>
          {specializedAmmoState.map((ammo: SpecializedAmmo) => {
            return (
              <React.Fragment key={ammo.id}>
                <div className="admin-page__special-ammo-box">
                  <div className="admin-page__special-ammo-box--title">
                    <h3>{ammo.name}</h3>
                    <button onClick={() => setSpecializedAmmoEdited(ammo)}>Edit</button>
                    <button onClick={() => handleDeleteSpecializedAmmo(ammo.id)}>Delete</button>
                  </div>
                  <p>{ammo.desc}</p>
                </div>
              </React.Fragment>
            );
          })}
        </>
        :
        <form className="admin-page__special-ammo-form" onSubmit={(e) => saveSpecializedAmmoChanges(e)}>
          <div className="admin-page__special-ammo-form--top-buttons">
            <button type="button" onClick={() => setSpecializedAmmoEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__special-ammo-form--inputs">
            <label>
              Name
              <input
                value={specializedAmmoEdited.name}
                onChange={(e) => setSpecializedAmmoEdited({...specializedAmmoEdited, name: e.target.value})}
                required
              />
            </label>
            <label>
              Description
              <textarea
                value={specializedAmmoEdited.desc}
                onChange={(e) => setSpecializedAmmoEdited({...specializedAmmoEdited, desc: e.target.value})}
                required
              />
            </label>
            <label>
              Lbs
              <input
                value={specializedAmmoEdited.lbs || 0}
                onChange={(e) => setSpecializedAmmoEdited({...specializedAmmoEdited, lbs: Number(e.target.value)})}
                type="number"
              />
            </label>
            <label>
              Type
              <select
                value={specializedAmmoEdited.type}
                onChange={(e) => setSpecializedAmmoEdited({...specializedAmmoEdited, type: e.target.value})}
                required
              >
                <option value="ammo">AMMO</option>
                <option value="explosive">GRENADES / EXPLOSIONS</option>
                <option value="concoction">CONCOCTIONS / ELIXERS</option>
              </select>
            </label>
            <label>
              AOE
              <input
                value={specializedAmmoEdited.aoe}
                onChange={(e) => setSpecializedAmmoEdited({...specializedAmmoEdited, aoe: Number(e.target.value)})}
                type="number"
                required
              />
            </label>
            <h2 style={{ 'marginBottom':'0' }}>Cost</h2>
            <label>
              Amount
              <input
                value={specializedAmmoEdited.cost.amount}
                onChange={(e) => setSpecializedAmmoEdited({...specializedAmmoEdited, cost: { ...specializedAmmoEdited.cost, amount: Number(e.target.value)}})}
                type="number"
                required
              />
            </label>
            <label>
              Currency
              <select
                value={specializedAmmoEdited.cost.currency}
                onChange={(e) => setSpecializedAmmoEdited({...specializedAmmoEdited, cost: { ...specializedAmmoEdited.cost, currency: e.target.value}})}
                required
              >
                <option value="gp">GP</option>
                <option value="sp">SP</option>
                <option value="cp">CP</option>
              </select>
            </label>
          </div>
        </form>
      }
    </div>
  );
}
