import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterBackstory } from "../../../redux/reducers/characterSlice";


export default function SheetBackstoryBlock() {
  const dispatch = useAppDispatch();
  const { backstory } = useAppSelector(fetchCharacterState);

  return (
    <div className="character-sheet__backstory">
      <p className="character-sheet__backstory--title">CHARACTER BACKSTORY</p>
      <textarea
        value={backstory}
        onChange={(e) => dispatch(setCharacterBackstory(e.target.value))}
      />
    </div>
  );
}
