import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import ResourcesPageTopBar from "../components/ResourcesPage/ResourcesPageTopBar";
import ResourcesPageLeftSidebar from "../components/ResourcesPage/ResourcesPageLeftSidebar";
import ResourcesPageShop from "../components/ResourcesPage/ResourcesPageShop";
import { useAppSelector } from "../redux/hooks";
import { fetchProductsState } from "../redux/reducers/productsSlice";
import { getPurchaseHistory } from "../scripts/controllers/shopController";
import { fetchUserState } from "../redux/reducers/userSlice";


export default function ResourcesPage() {
  const [defaultProductList, setDefaultProductList] = useState<Product[]>([]);
  const [productList, setProductList] = useState<Product[]>([]);
  const [purchaseHistory, setPurchaseHistory] = useState<PurchasedProduct[]>([]);
  const products = useAppSelector(fetchProductsState);
  const user = useAppSelector<User>(fetchUserState);

  useEffect(() => {
    const fetchData = async () => {  
      setPurchaseHistory(await getPurchaseHistory());
    };
    fetchData();
  }, [products]);

  useEffect(() => {
    const filteredList = products.filter((product: Product) => !purchaseHistory.some((p) => (p.product === product.id) && p.qty === product.metadata.maxQty));
    setProductList(filteredList);
    setDefaultProductList(filteredList);
  }, [purchaseHistory]);

  
  return (
    <Layout>
      <div className="resources-page">
        <ResourcesPageTopBar />
        <div className="resources-page__content">
          <ResourcesPageLeftSidebar
            setProductList={setProductList}
            defaultProductList={defaultProductList}
          />

          {/* TEMP */}
          {/* Remove check for admin users when shop is fully built */}
          {user.accesslevel > 1 ?
            <ResourcesPageShop products={productList} prodHistory={purchaseHistory} />
            :
            <h2 style={{ textAlign: 'center', width: '100%', marginTop: '-10rem', fontSize: '2.3rem' }}>
              Comming Soon!
            </h2>
          }
        </div>
      </div>
    </Layout>
  );
}
