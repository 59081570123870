import React, { useEffect } from "react";
import { pdfjs } from 'react-pdf';
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function HandbookPage() {
  const { book } = useParams() as any;
  const url = window.location.host === 'www.antediluvianttrpg.com' ? 'https://www.antediluvianttrpg.com' : 'http://localhost:3000';
  const page = new URLSearchParams(document.location.search).get('p') || 1;

  const purchaseDictionary: any = {
    handbook: `${url}/antediluvian_handbook.pdf#page=${page}`,
  };

  useEffect(() => {
    window.scrollTo(0, 190);
  }, []);


  return (
    <Layout>
      <div className="handbook-page">
        <object data={purchaseDictionary[book]} type="application/pdf">
          <iframe src={purchaseDictionary[book]}></iframe>
        </object>
      </div>
    </Layout>
  );
}