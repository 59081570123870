import React, { useEffect, useState } from "react";
import { clamp, convertItemPriceToDecimal } from "../../scripts/tools/utils";
import { editCart } from "../../scripts/controllers/shopController";
import { useAtom } from "jotai";
import { cartAtom } from "../../scripts/atoms/state";

interface Props {
  products: Product[]
  prodHistory: PurchasedProduct[]
}

interface Quantities {
  [key: string]: number
}


export default function ResourcesPageShop({ products, prodHistory }: Props) {
  const [cart, setCartData] = useAtom<Product[]>(cartAtom);
  const [quantities, setQuantities] = useState<Quantities>({});

  useEffect(() => {
    setQuantities(products.reduce((acc: Quantities, product: Product) => {
      return { ...acc, [product.id]: 1 };
    }, {} as Quantities));
  }, [products]);


  const handleQuantityChange = (productId: string, product: Product, value: any) => {
    const prod = prodHistory.find((prod: PurchasedProduct) => prod.product === productId);
    const maxQty = product.metadata.maxQty - (prod ? prod.qty : 0);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: clamp(Number(value), 0, maxQty)
    }));
  };

  const addProductToCart = async (product: Product) => {
    const updatedCart = [...cart, { ...product, quantity: quantities[product.id] || 1 }];
    await editCart(updatedCart);
    setCartData(updatedCart);
  };

  const canAddProductToCart = (product: Product): boolean => {
    if (!product.metadata.singleCopy) return true;
    if (!cart.find((p: Product) => p.id === product.id)) {
      return true;
    } else {
      return false;
    }
  };


  return (
    <div className="resources-page-shop">
      {products.length === 0 &&
        <h2>Thanks for your support!</h2>
      }
      {products.map((product: Product) => {
        return (
          <div key={product.id} className="resources-page-shop__product">
            <img
              className="resources-page-shop__product--image"
              src={product.images[0]}
              alt="Product image"
              draggable={false}
            />
            <div className="resources-page-shop__product--right-container">
              <h3 className="resources-page-shop__product--name">{ product.name }</h3>
              <p className="resources-page-shop__product--subtitle">{ product.metadata.subtitle }</p>
              <p>{ product.description }</p>
              <div className="resources-page-shop__product--box">
                <div className="resources-page-shop__product--box--qty">
                  <p className="resources-page-shop__product--price">
                    ${ convertItemPriceToDecimal(product.price.unit_amount) }
                  </p>
                  {product.metadata.maxQty > 1 && canAddProductToCart(product) &&
                    <label className="resources-page-shop__product--qty">
                      Qty
                      <input
                        type="number"
                        value={quantities[product.id] === 0 ? '' : quantities[product.id]}
                        onChange={(e) => handleQuantityChange(product.id, product, e.target.value)}
                      />
                    </label>
                  }
                </div>
                { product.metadata.preorder && <img className="resources-page-shop__product--pre-order" src="/images/pre_order.png" alt="pre order" /> }

                {canAddProductToCart(product) ?
                  <>
                    <button
                      className="resources-page-shop__product--add-to-cart-btn"
                      onClick={() => addProductToCart(product)}
                    >
                      <img
                        src="/images/buttons/add_to_cart_btn.png"
                        alt="Add to cart"
                      />
                    </button>
                  </>
                  :
                  <p className="resources-page-shop__product--added-to-cart-text">Added to cart!</p>
                }
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
