import React, { useEffect, useRef, useState } from "react";

interface Option {
  index: string | number;
  name: string;
  subOptions?: Option[];
  hoverContent?: { title: string; desc: string };
}

interface Props {
  classData: string;
  emptyValueText?: string;
  inputChange?: (value: string) => void;
  value?: Option;
  options: Option[];
}

export default function Dropdown({
  classData,
  options,
  emptyValueText,
  inputChange,
  value,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [subOptionsOpen, setSubOptionsOpen] = useState<boolean[]>([]);
  const [hoveredOption, setHoveredOption] = useState<Option | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value && value.index) setSelectedOption(value);
    const initialSubOptionsOpen = options.map(() => false);
    setSubOptionsOpen(initialSubOptionsOpen);
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [options, value]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubOptions = (index: number) => {
    setSubOptionsOpen((prev) => {
      const updatedState = prev.map((isOpen, i) => (i === index ? !isOpen : false));
      return updatedState;
    });
  };

  const selectOption = (option: Option) => {
    if (option.subOptions) return;
    setSelectedOption(option);
    inputChange?.(option.index.toString());
    setIsOpen(false);
    setSubOptionsOpen([]);
    setHoveredOption(null);
  };

  const handleParentOptionClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    const index = parseInt(event.currentTarget.dataset.index || "", 10);
    if (!isNaN(index)) {
      if (options[index].subOptions) {
        toggleSubOptions(index);
      } else {
        selectOption(options[index]);
      }
    }
  };

  const handleOptionHover = (option: Option) => {
    setHoveredOption(option);
  };

  const handleOptionLeave = () => {
    setHoveredOption(null);
  };

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${classData}`}
      onClick={toggleDropdown}
      onMouseLeave={handleOptionLeave}
    >
      <div className={`selected${isOpen ? "--open" : ""}`}>
        {selectedOption ? selectedOption.name : emptyValueText}
        <img src={`/images/icons/arrow-${isOpen ? "up" : "down"}.svg`} alt="Dropdown Arrow" draggable={false} />
      </div>
      {isOpen && (
        <ul className="dropdown__options">
          {options.map((option: Option, index: number) => (
            option &&
            <li
              key={option.index}
              onClick={handleParentOptionClick}
              data-index={index}
              onMouseEnter={() => option.hoverContent && handleOptionHover(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
      {isOpen && (
        <>
          {options.map((option: Option, index: number) => (
            option && option.subOptions &&
            subOptionsOpen[index] && (
              <ul key={option.index} className="dropdown__sub-options">
                {option.subOptions.map((subOption: Option) => (
                  <li key={subOption.index} onClick={() => selectOption(subOption)}>
                    {subOption.name}
                  </li>
                ))}
              </ul>
            )
          ))}
        </>
      )}
      {hoveredOption && (
        <div className="dropdown__hover-content">
          <div className="hover-box">
            <h3>{hoveredOption.hoverContent.title}</h3>
            <p>{hoveredOption.hoverContent.desc}</p>
          </div>
        </div>
      )}
    </div>
  );
}
