import api from "../config/axios";


// GET Routes

export const getAllPerks = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/perk', auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editPerk = async (perk: Perk) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/perk', perk, auth);
  } catch (error) {
    console.log(error);
  }
};

// POST Routes

export const addPerk = async (perk: Perk) => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/perk', perk, auth);
  } catch (error) {
    console.log(error);
  }
};

// DELETE Routes

export const deletePerk = async (id: number) => {
  try {
    const auth = { withCredentials: true };
    await api.delete(`/api/perk/${id}`, auth);
  } catch (error) {
    console.log(error);
  }
};
