import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { fetchProductsState } from "../../redux/reducers/productsSlice";
import { getPurchaseHistory } from "../../scripts/controllers/shopController";


export default function AccountPagePurchases() {
  const products = useAppSelector(fetchProductsState);
  const [purchaseHistory, setPurchaseHistory] = useState<string[]>([]);

  const getProduct = (productId: string): Product => {
    return products.find((product: Product) => product.id === productId);
  };

  useEffect(() => {
    const fetchData = async () => {  
      setPurchaseHistory(await getPurchaseHistory());
    };
    fetchData();
  }, []);


  return (
    <>
      <h2>Purchases</h2>
      <div className="account-page-purchases">
        {products.length > 0 && purchaseHistory.map((productId: string) => {
          const product = getProduct(productId);

          return (
            <div key={productId} className="purchases-page__product">
              <img src={product.images[0]} alt="product image" />
              <div className="cart-page__product--box">
                <div className="purchases-page__product--row">
                  <h2>{ product.name }</h2>
                </div>
                <div className="purchases-page__product--row">
                  <Link to={`/purchases/${product.metadata.routeName}`} className="purchases-page__link">See Book</Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
