import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState } from "../../../redux/reducers/characterSlice";


export default function SheetBlessingsBlock() {
  const characterState: Character = useAppSelector(fetchCharacterState);
  const [blankRows, setBlankRows] = useState([]);

  useEffect(() => {
    getBlankRows();
  }, [characterState]);

  // Get the amount of empty rows to render
  const getBlankRows = () => {
    const numOfBlankRows = 3 - characterState.blessings.length;
    const emptyRows = [];
    for (let i = 0; i < numOfBlankRows; i++) {
      emptyRows.push([...blankRows, i]);
    }
    setBlankRows(emptyRows);
  };


  return (
    <div className="character-sheet__blessing">
      {/* Blessings header */}
      <div className="character-sheet__blessing-header">
        <p>Blessings</p>
      </div>

      {/* Blessing content */}
      {characterState.blessings.map((blessing: Blessing, i) => {
        return (
          <div className="character-sheet__blessing--desc" key={i}>
            <p>{ blessing.name }: { blessing.desc }</p>
          </div>
        );
      })}

      {/* Blank rows */}
      {blankRows.map((row, i) => {
        const length = characterState.blessings.length + 2;
        return (
          <React.Fragment key={i}>
            <div className="character-sheet__blessing--desc"></div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
