import React, { FormEvent, useState } from "react";
import { registerUser } from "../scripts/controllers/userController";


export default function RegisterPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleRegisterUser = (e: any) => {
    e.preventDefault();
    const user = { username: username, password: password };
    registerUser(user);
  };


  return (
    <div className="register-page">
      <h1>REGISTER</h1>
      <form className="register-page__form" onSubmit={(e: FormEvent) => handleRegisterUser(e)}>
        <label>
          Username
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
        </label>
        <label>
          Password
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </label>
        <button type="submit">Register</button>
      </form>
      <p className="register-page__link">Already have an account? <a href="/login">Login</a></p>
    </div>
  );
}
