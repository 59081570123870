import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface ConditionsState {
  conditions: Condition[]
}

const initialState: ConditionsState = {
  conditions: [{
    id: 0,
    name: '',
    desc: '',
  }],
};

export const conditionsSlice = createSlice({
  name: 'condition',
  initialState,
  reducers: {
    setConditionsData: (state, action) => { state.conditions = action.payload; },
  }
});

export const {
  setConditionsData,
} = conditionsSlice.actions;

export const fetchConditionsState = (state: RootState): Condition[] => state.conditions.conditions;

export default conditionsSlice.reducer;