import React, { useRef } from "react";
import { defaultProfilePics } from "../../scripts/profilePicData";
import { deleteImageFirebase, uploadImageFirebase } from "../../scripts/tools/firebaseUtils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchUserState, setUserData } from "../../redux/reducers/userSlice";
import { setUserProfilePic } from "../../scripts/controllers/userController";


interface Props {
  showMenu: (T: boolean) => void
}

export default function ProfilePicSelection({ showMenu }: Props) {
  const dispatch = useAppDispatch();
  const userState: User = useAppSelector(fetchUserState);
  const inputFile = useRef<HTMLInputElement | null>(null);

  const selectCustomProfilePick = () => {
    inputFile.current.click();
  };

  const uploadProfilePic = async (imageFile: File) => {
    if (!imageFile) return;
    if (userState.profilePic) await deleteImageFirebase(userState.profilePic);
    await uploadImageFirebase(imageFile, (url: string) => {
      setUserProfilePic(url);
      dispatch(setUserData({ ...userState, profilePic: url }));
    });
  };

  const selectProfilePick = async (url: string) => {
    await setUserProfilePic(url);
    if (userState.profilePic) await deleteImageFirebase(userState.profilePic);
    dispatch(setUserData({ ...userState, profilePic: url }));
  };


  return (
    <div className="profile-pic-selection">
      <button
        className="profile-pic-selection__close-btn"
        onClick={() => showMenu(false)}
      >
        X
      </button>

      <div className="profile-pic-selection__pics-container">
        <button
          className="profile-pic-selection__upload-btn"
          onClick={selectCustomProfilePick}
        >
          Upload
        </button>

        {defaultProfilePics.map((url: string, i) => {
          return (
            <button
              key={i}
              onClick={() => selectProfilePick(url)}
            >
              <img
                src={url}
                alt="Profile picture option"
                draggable={false}
                loading="lazy"
              />
            </button>
          );
        })}
      </div>

      <input
        type='file'
        onChange={(e) => uploadProfilePic(e.target.files[0])}
        ref={inputFile}
        style={{ display: 'none' }}
        accept="image/png, image/gif, image/jpeg"
      />
    </div>
  );
}
