import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState } from "../../../redux/reducers/characterSlice";


export default function SheetHeritageTraitsBlock() {
  const { traits } = useAppSelector(fetchCharacterState);


  return (
    <div className="character-sheet__heritage-traits">
      <p className="character-sheet__heritage-traits--title">HERITAGE TRAITS</p>
      <div className="character-sheet__heritage-traits-container">
        {traits.length > 0 &&
          <>
            <h2>Positive Traits</h2>
            <p>{ traits[0].trait }</p>
            <p>{ traits[1].trait }</p>

            <h2>Negative Traits</h2>
            <p>{ traits[2].trait }</p>
          </>
        }
      </div>
    </div>
  );
}
