import React, { useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { getPurchaseHistory } from "../scripts/controllers/shopController";
import { fetchProductsState } from "../redux/reducers/productsSlice";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";


export default function PurchasesPage() {
  const products = useAppSelector(fetchProductsState);
  const [purchaseHistory, setPurchaseHistory] = useState<PurchasedProduct[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPurchaseHistory();
      setPurchaseHistory(res);
    };
    fetchData();
  }, []);

  const getProduct = (productId: string): Product => {
    return products.find((product: Product) => product.id === productId);
  };


  return (
    <Layout>
      <div className="purchases-page-container">
        <div className="purchases-page">
          <h1>My Purchases</h1>
          {products.length > 0 && purchaseHistory.map((prod: PurchasedProduct) => {
            const product = getProduct(prod.product);
            const url = product.metadata.routeName;

            return (
              <div key={prod.id} className="purchases-page__product">
                <img src={product.images[0]} alt="product image" />
                <div className="cart-page__product--box">
                  <div className="purchases-page__product--row">
                    <h2>{ product.name }</h2>
                  </div>
                  { prod.qty > 1 && <p>Qty: { prod.qty }</p> }
                  <div className="purchases-page__product--row">
                    { url && <Link to={`/purchases/${url}`} className="purchases-page__link">See Book</Link> }
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Link to="/resources" className="purchases-page__back-btn">Back</Link>
      </div>
    </Layout>
  );
}
