import React from "react";
import Layout from "../components/Layout";


export default function VTT() {
  return (
    <Layout>
      <div>
        <h1 style={{ textAlign: 'center', marginBottom: '31%', fontSize: '4rem' }}>Comming Soon!</h1>
      </div>
    </Layout>
  );
}
