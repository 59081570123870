import React, { useEffect, useState } from "react";
import { getCharacters } from "../../scripts/controllers/characterController";
import { Link } from "react-router-dom";


export default function AccountPageCharacters() {
  const [characters, setCharacters] = useState<Character[]>([]);

  useEffect(() => {
    fetchCharacterData();
  }, []);

  const fetchCharacterData = async () => {
    const data: Character[] = await getCharacters() as any;
    setCharacters(data);
  };


  return (
    <>
      <h2>Characters</h2>
      <div className="account-page-characters">
        {characters.map((character: Character) => {
          return (
            <div key={character.id} className="account-page-characters__character">
              <Link to={`/character-sheet/${character.id}`}>
                <p className="account-page-characters__character--name">{character.name}</p>
              </Link>
              <p className="account-page-characters__character--class">{character.characterClass}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}
