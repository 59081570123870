import React, { useEffect, useState } from "react";


export default function Footer() {
  const [year, setYear] = useState(0);

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);


  return (
    <div className="footer">
      <p>&copy; { year } Antediluvian is owned by Faith Forged Games</p>
    </div>
  );
}
