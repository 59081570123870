import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface GearState {
  equipment: Gear[]
  armor: Gear[]
  tools: Gear[]
  instruments: Gear[]
  rations: Gear[]
}

const initialState: GearState = {
  equipment: [{
    id: 0,
    index: '',
    name: '',
    desc: '',
    lbs: 0,
    cost: { amount: 0, currency: '' },
    type: '',
    qty: 0,
    carried: false,
  }],
  armor: [{
    id: 0,
    index: '',
    name: '',
    desc: '',
    lbs: 0,
    cost: { amount: 0, currency: '' },
    type: '',
    defense: 0,
    qty: 0,
    carried: false,
    subtype: ''
  }],
  tools: [{
    id: 0,
    index: '',
    name: '',
    desc: '',
    lbs: 0,
    cost: { amount: 0, currency: '' },
    type: '',
    qty: 0,
    carried: false,
  }],
  instruments: [{
    id: 0,
    index: '',
    name: '',
    desc: '',
    lbs: 0,
    cost: { amount: 0, currency: '' },
    type: '',
    qty: 0,
    carried: false,
  }],
  rations: [{
    id: 0,
    index: '',
    name: '',
    desc: '',
    lbs: 0,
    cost: { amount: 0, currency: '' },
    type: '',
    qty: 0,
    carried: false,
  }],
};

export const gearSlice = createSlice({
  name: 'gear',
  initialState,
  reducers: {
    setEquipmentData: (state, action) => { state.equipment = action.payload; },
    setArmorData: (state, action) => { state.armor = action.payload; },
    setToolsData: (state, action) => { state.tools = action.payload; },
    setInstrumentsData: (state, action) => { state.instruments = action.payload; },
    setRationsData: (state, action) => { state.rations = action.payload; },
  }
});

export const {
  setEquipmentData,
  setArmorData,
  setToolsData,
  setInstrumentsData,
  setRationsData,
} = gearSlice.actions;

export const fetchGearState = (state: RootState) => state.gear;

export default gearSlice.reducer;