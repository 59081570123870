import React from "react";
import AccountPagePurchases from "./AccountPagePurchases";
import AccountPageCharacters from "./AccountPageCharacters";


interface Props {
  infoPanelState: string
}

export default function AccountPageInfoPanel({ infoPanelState }: Props) {
  return (
    <div className="account-page__info-panel">
      {infoPanelState === '' &&
        <div>
          
        </div>
      }
      {infoPanelState === 'characters' &&
        <AccountPageCharacters />
      }
      {infoPanelState === 'purchases' &&
        <AccountPagePurchases />
      }
      {/* {infoPanelState === 'messages' &&
        <div>
          <p>Messages</p>
        </div>
      }
      {infoPanelState === 'forum' &&
        <div>
          <p>Forum</p>
        </div>
      } */}
    </div>
  );
}
