import api from '../config/axios';


// GET Routes

export const getAllCultureTraits = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/culture-trait', auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editCultureTrait = async (trait: CultureTrait) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/culture-trait', trait, auth);
  } catch (error) {
    console.log(error);
  }
};
