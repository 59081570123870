import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { fetchPerksState } from "../../redux/reducers/perksSlice";
import { addPerk, deletePerk, editPerk } from "../../scripts/controllers/perksController";


export default function AdminPerks() {
  const perksState: Perk[] = useAppSelector(fetchPerksState);
  const [perkEdited, setPerkEdited] = useState<Perk>();

  const savePerkChanges = async (e: FormEvent) => {
    e.preventDefault();
    if (perkEdited.parent === 'new-perk') {
      const updatedPerk = {...perkEdited, parent: ''};
      await addPerk(updatedPerk);
    } else {
      await editPerk(perkEdited);
    }
    window.location.href = '/admin?s=perks';
  };

  const handleDeletePerk = async (id: number) => {
    if (confirm('Are you sure?')) {
      await deletePerk(id);
      window.location.href = '/admin?s=perks';
    }
  };


  return (
    <div className="admin-page__perks">
      {!perkEdited ?
        <>
          <div className="admin-page__perks-form--top-buttons">
            <button onClick={() => setPerkEdited({ id: 0, parent: 'new-perk', desc: '' })}>Add</button>
          </div>
          {perksState.map((perk: Perk) => {
            return (
              <React.Fragment key={perk.id}>
                <div className="admin-page__perks-box">
                  <div className="admin-page__perks-box--title">
                    <h3>{ perk.parent && <span className="admin-page__perks-box--parent">({ perk.parent })</span> } {perk.desc}</h3>
                    <button onClick={() => setPerkEdited(perk)}>Edit</button>
                    <button onClick={() => handleDeletePerk(perk.id)}>Delete</button>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </>
        :
        <form className="admin-page__perks-form" onSubmit={(e) => savePerkChanges(e)}>
          <div className="admin-page__perks-form--top-buttons">
            <button type="button" onClick={() => setPerkEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__perks-form--inputs">
            <label>
              Description
              <textarea
                value={perkEdited.desc}
                onChange={(e) => setPerkEdited({...perkEdited, desc: e.target.value})}
                required
              />
            </label>
            <label>
              Category
              <select
                onChange={(e) => setPerkEdited({...perkEdited, parent: e.target.value})}
                value={perkEdited.parent}
              >
                <option value="">None</option>
                <option value="language">Language</option>
                <option value="tool">Tool</option>
                <option value="instrument">Instrument</option>
              </select>
            </label>
          </div>
        </form>
      }
    </div>
  );
}
