import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import RegisterPage from '../pages/RegisterPage';
import LoginPage from '../pages/LoginPage';
import HomePage from "../pages/HomePage";
import CharactersPage from "../pages/CharactersPage";
import CharacterSheetPage from "../pages/CharacterSheetPage";
import Layout from "./Layout";
import { useAppDispatch } from "../redux/hooks";
import { getAllGearOfType } from "../scripts/controllers/gearController";
import { setArmorData, setEquipmentData, setInstrumentsData, setRationsData, setToolsData } from "../redux/reducers/gearSlice";
import { setBlessingsData } from "../redux/reducers/blessingsSlice";
import { getAllBlessings } from "../scripts/controllers/blessingsController";
import { getAllWeapons } from "../scripts/controllers/weaponsController";
import { setWeaponsData } from "../redux/reducers/weaponsSlice";
import { getAllPerks } from "../scripts/controllers/perksController";
import { setPerksData } from "../redux/reducers/perksSlice";
import { getAllLanguages } from "../scripts/controllers/languageController";
import { setLanguagesData } from "../redux/reducers/languagesSlice";
import { getAllFeatures } from "../scripts/controllers/featuresController";
import { setFeaturesData } from "../redux/reducers/featuresSlice";
import AccountPage from "../pages/AccountPage";
import AdminPage from "../pages/AdminPage";
import { getFeaturedContent } from "../scripts/controllers/featuredContentController";
import { setFeaturedContentsData, setSmallFeaturedContentsData } from "../redux/reducers/featuredContentSlice";
import { setConditionsData } from "../redux/reducers/conditionsSlice";
import { getConditions } from "../scripts/controllers/conditionsController";
import ResourcesPage from "../pages/ResourcesPage";
import CartPage from "../pages/CartPage";
import { getAllProducts, getCart } from "../scripts/controllers/shopController";
import { getAllSpecialAmmo } from "../scripts/controllers/specialAmmoController";
import { setSpecializedAmmoData } from "../redux/reducers/specialAmmoSlice";
import { getAllCultureTraits } from "../scripts/controllers/cultureTraitsController";
import { setCultureTraitsData } from "../redux/reducers/cultureTraitsSlice";
import { setUserData } from "../redux/reducers/userSlice";
import PurchasesPage from "../pages/PurchasesPage";
import { setProductsData } from "../redux/reducers/productsSlice";
import HandbookPage from "../pages/HandbookPage";
import { useAtom } from "jotai";
import { cartAtom } from "../scripts/atoms/state";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import VTT from "../pages/VTT";


interface Props {
  user: User
}

export default function App({ user }: Props) {
  const dispatch = useAppDispatch();
  const [cart, setCartData] = useAtom<Product[]>(cartAtom);
  
  useEffect(() => {
    fetchInitialData();
  }, []);

  dispatch(setUserData(user));
  
  // Get static data from the database right away that won't be changed
  const fetchInitialData = async () => {
    // Gear
    const equipment: Gear[] = await getAllGearOfType('equipment') as any;
    const armor: Gear[] = await getAllGearOfType('armor') as any;
    const tools: Gear[] = await getAllGearOfType('tool') as any;
    const instruments: Gear[] = await getAllGearOfType('instrument') as any;
    const rations: Gear[] = await getAllGearOfType('ration') as any;
    dispatch(setEquipmentData(equipment));
    dispatch(setArmorData(armor));
    dispatch(setToolsData(tools));
    dispatch(setInstrumentsData(instruments));
    dispatch(setRationsData(rations));
    // // Weapons
    const weapons: Weapon[] = await getAllWeapons() as any;
    dispatch(setWeaponsData(weapons));
    // Blessings
    const blessings: Blessing[] = await getAllBlessings() as any;
    dispatch(setBlessingsData(blessings));
    // // Perks
    const perks: Perk[] = await getAllPerks() as any;
    dispatch(setPerksData(perks));
    // Languages
    const languages: Language[] = await getAllLanguages() as any;
    dispatch(setLanguagesData(languages));
    // Culture Traits
    const cultureTraits: CultureTrait[] = await getAllCultureTraits() as any;
    dispatch(setCultureTraitsData(cultureTraits));
    // Features
    const features: FeatureDisplay[] = await getAllFeatures() as any;
    dispatch(setFeaturesData(features));
    // Featured Content
    const featuredContent: FeaturedContent = await getFeaturedContent() as any;
    dispatch(setFeaturedContentsData(featuredContent ? featuredContent : {...featuredContent, featureBoxImgArray: ['']}));
    const smallFeaturedContent: FeaturedContent = await getFeaturedContent() as any;
    dispatch(setSmallFeaturedContentsData(smallFeaturedContent));
    // Conditions
    const conditions: Condition = await getConditions() as any;
    dispatch(setConditionsData(conditions));
    // Specialized Ammo
    const specializedAmmo: SpecializedAmmo = await getAllSpecialAmmo() as any;
    dispatch(setSpecializedAmmoData(specializedAmmo));
    // Cart
    const cart: Product[] = await getCart() as any;
    setCartData(cart || []);
    
    // Products
    const products: Product[] = await getAllProducts() as any;
    dispatch(setProductsData(products));
  };


  return (
    <Router>
      <div>
        <Switch>
          {/* Login page */}
          <Route exact path="/login">
            <LoginPage />
          </Route>

          {/* Register page */}
          <Route exact path="/register">
            <RegisterPage />
          </Route>

          {/* Home page */}
          <Route exact path="/">
            <HomePage />
          </Route>

          {/* Characters page */}
          <Route exact path="/characters">
            {
              user ?
                <CharactersPage />
                :
                <Redirect exact to="/login" />
            }
          </Route>

          {/* Character Sheet page */}
          <Route exact path="/character-sheet/:characterId">
            {
              user ?
                <CharacterSheetPage />
                :
                <Redirect exact to="/login" />
            }
          </Route>

          {/* Resources page */}
          <Route exact path="/resources">
            {
              user ?
                <ResourcesPage />
                :
                <Redirect exact to="/login" />
            }
          </Route>

          {/* Cart page */}
          <Route exact path="/resources/cart">
            {
              user ?
                <CartPage />
                :
                <Redirect exact to="/login" />
            }
          </Route>

          {/* Purchases page */}
          <Route exact path="/purchases">
            {
              user ?
                <PurchasesPage />
                :
                <Redirect exact to="/login" />
            }
          </Route>

          {/* Account page */}
          <Route exact path="/account">
            {
              user ?
                <AccountPage />
                :
                <Redirect exact to="/login" />
            }
          </Route>

          {/* Handbook page */}
          <Route exact path="/purchases/:book">
            {
              user ?
                <HandbookPage />
                :
                <Redirect exact to="/login" />
            }
          </Route>

          {/* Reset Password page */}
          <Route exact path="/reset-password">
            {
              <ResetPasswordPage />
            }
          </Route>

          {/* VTT page */}
          <Route exact path="/vtt">
            {
              user ?
                <VTT />
                :
                <Redirect exact to="/login" />
            }
          </Route>

          {/* Admin page */}
          <Route exact path="/admin">
            { 
              user ?
                user.accesslevel === 2 ?
                  <AdminPage />
                  :
                  <Layout>
                    <h1>Error 404: Page not found</h1>
                  </Layout>
                :
                <Redirect exact to="/login" />
            }
          </Route>

          <Route>
            <Layout>
              <h1>Error 404: Page not found</h1>
            </Layout>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
