import React, { useEffect, useState } from "react";
import { fetchCharacterState, setCharacterWeapons } from "../../../redux/reducers/characterSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchWeaponsState } from "../../../redux/reducers/weaponsSlice";
import Dropdown from "../../Dropdowns/Dropdown";
import { filterWeaponsByProperty, getClassWeaponsUsed } from "../../../scripts/characterSheetLogic";
import { getFirstTwoLettersOfDmg } from "../../../scripts/tools/stringUtils";


export default function SheetWeaponsBlock() {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const weaponsState: Weapon[] = useAppSelector(fetchWeaponsState);
  const [blankRows, setBlankRows] = useState([]);
  const descBox: any = document.getElementById('desc-box');
  const descBoxText: any = document.querySelector('#desc-box p');
  const [selectAmmoMenuOpen, setSelectAmmoMenuOpen] = useState<Weapon>(null);  

  
  useEffect(() => {
    getBlankRows();
  }, [characterState]);

  const showDescOnHover = (e: any, desc: string) => {
    descBox.classList.remove('hidden');
    descBox.style.top = `${e.pageY}px`;
    descBox.style.left = `${e.pageX + 60}px`;
    descBoxText.innerText = desc;
  };

  const showDescStopHover = (e: any) => {
    descBox.classList.add('hidden');
    descBoxText.innerText = '';
  };

  // Get the amount of empty rows to render
  const getBlankRows = () => {
    const numOfBlankRows = 6 - characterState.weapons.length;
    const emptyRows = [];
    for (let i = 0; i < numOfBlankRows; i++) {
      emptyRows.push([...blankRows, i]);
    }
    setBlankRows(emptyRows);
  };
  
  // Detect if the character already has the same weapon as passed in
  const findCharacterAlreadyHasWeapon = (index: string): boolean => {
    return characterState.weapons.some((weapon: Weapon) => weapon.index === index);    
  };
  
  // Update weapon reducer with new weapon data
  // If the weapon already exists then add 1 to the qty
  const handleAddNewWeapon = async (index: string) => {  
    const newWeapon: Weapon = weaponsState.find((weapon: Weapon) => {
      return weapon.index === index;
    });
    
    const characterAlreadyHasGear = findCharacterAlreadyHasWeapon(index);
    if (characterAlreadyHasGear) {
      const updatedCharacterState = characterState.weapons.map((weapon: Weapon) => {
        if (weapon.index === index) {
          return {
            id: weapon.id,
            index: weapon.index,
            name: weapon.name,
            desc: weapon.desc,
            range: weapon.range,
            properties: weapon.properties,
            exhaustionInflicted: weapon.exhaustionInflicted,
            lbs: weapon.lbs,
            cost: weapon.cost,
            qty: weapon.qty + 1,
            carried: weapon.carried,
          };
        }
        return weapon;
      });
      dispatch(setCharacterWeapons(updatedCharacterState));
    } else {
      dispatch(setCharacterWeapons([...characterState.weapons, newWeapon]));
    }
  };
  
  // Delete a weapon row
  // TODO: If the qty is greater than 1 it should give the option to remove a certain amount
  const removeBlankRow = (index: string) => {
    const updatedCharacterState = characterState.weapons.map((weapon: Weapon) => {
      if (weapon.index === index) {
        return {
          id: weapon.id,
          weaponId: weapon.weaponId,
          index: weapon.index,
          name: weapon.name,
          desc: weapon.desc,
          range: weapon.range,
          properties: weapon.properties,
          exhaustionInflicted: weapon.exhaustionInflicted,
          lbs: weapon.lbs,
          cost: weapon.cost,
          qty: weapon.qty - 1,
          carried: weapon.carried,
          canHaveSpecialAmmo: weapon.canHaveSpecialAmmo,
        };
      } else {
        return weapon;
      }
    });    
    dispatch(setCharacterWeapons(updatedCharacterState.filter((weapon: Weapon) => {
      return weapon.qty > 0;
    })));
  };

  const equipSpecialAmmo = async (ammo: SpecializedAmmo, weaponId: number) => {
    setSelectAmmoMenuOpen(null);
    // if (!ammo) {
    //   await updateEquipedWeaponAmmo(null, weaponId);
    // } else {
    //   await updateEquipedWeaponAmmo(ammo.id, weaponId);
    // }
    const updatedWeapons = characterState.weapons.map((weapon: Weapon) => {
      if (weapon.id === weaponId) {
        return { ...weapon, specializedAmmo: ammo };
      }
      return weapon;
    });
    dispatch(setCharacterWeapons(updatedWeapons));
  };
  

  return (
    <div className="character-sheet__weapons">
      {/* Gear header */}
      <div className="character-sheet__field character-sheet__weapons-header character-sheet__weapons-header--name-title">
        <p>Weapons</p>
      </div>
      <div className="character-sheet__field character-sheet__weapons-header character-sheet__weapons-header--range-title">
        <p>Range</p>
      </div>
      <div className="character-sheet__field character-sheet__weapons-header character-sheet__weapons-header--exhaustion-title">
        <p>Exhaustion Inflicted</p>
      </div>
      <div className="character-sheet__field character-sheet__weapons-header character-sheet__weapons-header--lbs-title">
        <p>Lbs</p>
      </div>

      {/* Gear content */}
      {characterState.weapons.map((weapon: Weapon, i) => {
        return (
          <React.Fragment key={i}>
            {/* "i + 2" is to put it under the titles */}
            <div
              onMouseEnter={(e) => showDescOnHover(e, weapon.desc ? weapon.desc : 'No description')}
              onMouseLeave={(e) => showDescStopHover(e)}
              className={`character-sheet__field character-sheet__weapons--name-${i + 2}`}
            >
              {/* Specialized ammo section */}
              { weapon.canHaveSpecialAmmo &&
                <>
                  <button
                    className="character-sheet__weapons--special-ammo-btn"
                    onClick={() => setSelectAmmoMenuOpen(weapon)}
                    onMouseEnter={(e) => showDescStopHover(e)}
                  >
                    <img src="/images/icons/arrow-special-ammo-icon.svg" />
                  </button>
                  {selectAmmoMenuOpen && selectAmmoMenuOpen.id === weapon.id &&
                  <div className="character-sheet__weapons--special-ammo-selection">
                    <h4>Equip Ammo</h4>
                    <button onClick={() => equipSpecialAmmo(null, weapon.id)}>Normal</button>
                    {characterState.specializedAmmo.map((ammo: SpecializedAmmo) => {
                      if (ammo.type === 'ammo') {
                        return (
                          <button
                            key={ammo.id}
                            onClick={() => equipSpecialAmmo(ammo, weapon.id)}
                          >
                            { ammo.name }
                          </button>
                        );
                      }
                    })}
                  </div>
                  }
                </>
              }
              <p>{ weapon.name } <span>{ weapon.specializedAmmo && `(${weapon.specializedAmmo.name})` }</span></p>
            </div>
            <div className={`character-sheet__field character-sheet__weapons--range-${i + 2}`}>
              {weapon.range.min > 0 ?
                weapon.range.max > 0 ?
                  <p>{ weapon.range.min }/{ weapon.range.max } ft</p>
                  :
                  <p>{ weapon.range.min }</p>
                :
                <p>melee</p>
              }
            </div>
            <div className={`character-sheet__field character-sheet__weapons--exhaustion-${i + 2}`}>
              <p>
                {weapon.exhaustionInflicted.map((damage: Damage, index) =>{
                  const length = weapon.exhaustionInflicted.length;
                  return (
                    <React.Fragment key={index}>
                      { damage.dice.display } ({ getFirstTwoLettersOfDmg(damage.types).join(', ') }){ length !== index + 1 }
                    </React.Fragment>
                  );
                })}
              </p>
            </div>
            <div className={`character-sheet__field character-sheet__weapons--lbs-${i + 2}`}>
              <p>{ weapon.lbs }</p>

              <button
                className="character-sheet__weapons--delete-btn"
                onClick={() => removeBlankRow(weapon.index)}
              >
                <img src="/images/icons/trash.svg" alt="Trash can" draggable={false} />
              </button>
            </div>
          </React.Fragment>
        );
      })}

      {/* Blank rows */}
      {blankRows.map((row, i) => {
        const weaponsAvailable = getClassWeaponsUsed(characterState.characterClass);
        const length = characterState.weapons.length + 2;
        return (
          <React.Fragment key={i}>
            {/* Checks if this is the first blank row */}
            {/* Makes the first blank row an input to add a new weapon */}
            {row === blankRows[0] && weaponsAvailable ?
              <>
                <Dropdown
                  classData={`character-sheet__field character-sheet__weapons--name-${i + length} pdf-remove`}
                  emptyValueText="-- ADD WEAPON --"
                  inputChange={(value: string) => handleAddNewWeapon(value)}
                  value={{ index: 'test', name: "-- ADD WEAPON --" }}
                  options={[
                    weaponsAvailable.light && { index: 'light', name: 'light', subOptions: filterWeaponsByProperty(weaponsState, 'light') },
                    weaponsAvailable.oneHanded && { index: 'one-handed', name: 'one-handed', subOptions: filterWeaponsByProperty(weaponsState, 'one-handed') },
                    weaponsAvailable.twoHanded && { index: 'two-handed', name: 'two-handed', subOptions: filterWeaponsByProperty(weaponsState, 'two-handed') },
                    weaponsAvailable.polearms && { index: 'polearms', name: 'polearms', subOptions: filterWeaponsByProperty(weaponsState, 'polearms') },
                    weaponsAvailable.ranged && { index: 'ranged', name: 'ranged', subOptions: filterWeaponsByProperty(weaponsState, 'ranged') },
                    weaponsAvailable.specializedAmmo && { index: 'specialized-ammo', name: 'specialized-ammo', subOptions: filterWeaponsByProperty(weaponsState, 'specialized-ammo') },
                    weaponsAvailable.grenadesAndExplosives && { index: 'grenades/explosives', name: 'grenades/explosives', subOptions: filterWeaponsByProperty(weaponsState, 'grenades/explosives') },
                    weaponsAvailable.concoctionsAndElixers && { index: 'concoctions/elixers', name: 'concoctions/elixers', subOptions: filterWeaponsByProperty(weaponsState, 'concoctions/elixers') },
                    weaponsAvailable.unique && { index: 'unique', name: 'unique', subOptions: filterWeaponsByProperty(weaponsState, 'unique') },
                  ]}
                />
                <div className={`character-sheet__field character-sheet__weapons--range-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__weapons--exhaustion-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__weapons--lbs-${i + length}`}></div>
              </>
              :
              <>
                <div className={`character-sheet__field character-sheet__weapons--name-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__weapons--range-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__weapons--exhaustion-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__weapons--lbs-${i + length}`}></div>
              </>
            }
          </React.Fragment>
        );
      })}
      <div className="character-sheet__field character-sheet__weapons--mod">
        <p><span>Weapon Exhaustion Modifier</span></p>
        <span className="character-sheet__weapons--mod-border"></span>
        <p>+{ characterState.weaponExhaustionMod }</p>
      </div>
    </div>
  );
}
