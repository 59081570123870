import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface PerksState {
  perks: Perk[]
}

const initialState: PerksState = {
  perks: [
    { id: 0, desc: '', parent: '' },
  ],
};

export const perksSlice = createSlice({
  name: 'perks',
  initialState,
  reducers: {    
    setPerksData: (state, action) => { state.perks = action.payload; },
  }
});

export const {
  setPerksData,
} = perksSlice.actions;

export const fetchPerksState = (state: RootState): Perk[] => state.perks.perks;

export default perksSlice.reducer;