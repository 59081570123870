import api from "../config/axios";


// GET Routes

export const getAllProducts = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/stripe', auth);
    const modifiedRes = res.data.filter((product: Product) => product.active).map((product: Product) => {
      const preorder: boolean = product.metadata.preorder as any === 'true';
      const singleCopy: boolean = product.metadata.singleCopy as any === 'true';
      return {
        ...product,
        metadata: {
          ...product.metadata,
          order: Number(product.metadata.order),
          preorder: preorder,
          singleCopy: singleCopy,
          maxQty: Number(product.metadata.maxQty)
        }
      };
    });
    
    const prices = await getAllProductPrices();
    const products = modifiedRes.sort((productA: Product, productB: Product) => {
      return productA.metadata.order - productB.metadata.order;
    });

    return products.map((product: Product) => {
      const price = prices.find((_price: Price) => _price.product === product.id);
      return { ...product, price: price };
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllProductPrices = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/stripe/price', auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCart = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/cart', auth);
    return JSON.parse(res.data.products);
  } catch (error) {
    console.log(error);
  }
};

export const getPurchaseHistory = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/purchase', auth);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// POST Routes

interface CheckoutItem {
  price: string
  quantity: number
}

export const checkout = async (cart: CheckoutItem[]) => {
  try {
    const auth = { withCredentials: true };
    const url = await api.post(`/api/stripe/checkout`, { cart: JSON.stringify(cart) }, auth);
    const id = url.data.id;
    window.location.replace(url.data.url);
  } catch (error) {
    console.log(error);
  }
};

export const createCart = async () => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/cart', auth);
  } catch (error) {
    console.log(error);
  }
};

export const addPurchaseToHistory = async (productStripeId: string) => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/purchase', { product: productStripeId }, auth);
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const editCart = async (cart: Product[]) => {
  try {
    const auth = { withCredentials: true };
    await api.put('/api/cart', { cart: cart }, auth);
  } catch (error) {
    console.log(error);
  }
};
