import React, { FormEvent, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { fetchFeaturesState } from "../../redux/reducers/featuresSlice";
import { capitalize } from "../../scripts/tools/stringUtils";
import { editFeature } from "../../scripts/controllers/featuresController";


export default function AdminFeatures() {
  const featuresState: FeatureDisplay[] = useAppSelector(fetchFeaturesState);
  const [featureEdited, setFeatureEdited] = useState<FeatureDisplay>();

  const saveFeatureChanges = async (e: FormEvent) => {
    e.preventDefault();
    await editFeature(featureEdited);
    window.location.href = '/admin?s=features';
  };


  return (
    <div className="admin-page__features">
      {!featureEdited ?
        featuresState.map((featureDisplay: FeatureDisplay) => {
          return (
            <React.Fragment key={featureDisplay.index}>
              <h2>{featureDisplay.level === 1 && capitalize(featureDisplay.class)}</h2>
              <div className="admin-page__features-box">
                <div className="admin-page__features-box--title">
                  <h3>LEVEL {featureDisplay.level}: {featureDisplay.title}</h3>
                  <button onClick={() => setFeatureEdited(featureDisplay)}>Edit</button>
                </div>
                <p>{featureDisplay.desc}</p>
              </div>
            </React.Fragment>
          );
        })
        :
        <form className="admin-page__features-form" onSubmit={(e) => saveFeatureChanges(e)}>
          <div className="admin-page__features-form--top-buttons">
            <button type="button" onClick={() => setFeatureEdited(null)}>Cancel</button>
            <button type="submit">Save</button>
          </div>

          <div className="admin-page__features-form--inputs">
            <label>
              Title
              <input
                value={featureEdited.title}
                onChange={(e) => setFeatureEdited({...featureEdited, title: e.target.value})}
                required
              />
            </label>
            <label>
              Description
              <textarea
                value={featureEdited.desc}
                onChange={(e) => setFeatureEdited({...featureEdited, desc: e.target.value})}
                required
              />
            </label>

            <div className="admin-page__features-form--feature-list">
              <div>
                <h3>TRAIT OF BATTLE</h3>
                <label>
                  Name
                  <input
                    value={featureEdited.featureList[0].name}
                    onChange={(e) => {
                      setFeatureEdited({
                        ...featureEdited, featureList: featureEdited.featureList.map((feature: Feature) => {
                          if (feature.name === featureEdited.featureList[0].name) {
                            return {...feature, name: e.target.value};
                          } else {
                            return feature;
                          }
                        })
                      });
                    }}
                  />
                </label>
                <label>
                  Description
                  <textarea
                    value={featureEdited.featureList[0].desc}
                    onChange={(e) => {
                      setFeatureEdited({
                        ...featureEdited, featureList: featureEdited.featureList.map((feature: Feature) => {
                          if (feature.desc === featureEdited.featureList[0].desc) {
                            return {...feature, desc: e.target.value};
                          } else {
                            return feature;
                          }
                        })
                      });
                    }}
                  />
                </label>
              </div>

              <div>
                <h3>TRAIT OF GRACE</h3>
                <label>
                  Name
                  <input
                    value={featureEdited.featureList[1].name}
                    onChange={(e) => {
                      setFeatureEdited({
                        ...featureEdited, featureList: featureEdited.featureList.map((feature: Feature) => {
                          if (feature.name === featureEdited.featureList[1].name) {
                            return {...feature, name: e.target.value};
                          } else {
                            return feature;
                          }
                        })
                      });
                    }}
                  />
                </label>
                <label>
                  Description
                  <textarea
                    value={featureEdited.featureList[1].desc}
                    onChange={(e) => {
                      setFeatureEdited({
                        ...featureEdited, featureList: featureEdited.featureList.map((feature: Feature) => {
                          if (feature.desc === featureEdited.featureList[1].desc) {
                            return {...feature, desc: e.target.value};
                          } else {
                            return feature;
                          }
                        })
                      });
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
        </form>
      }
    </div>
  );
}
